import { clsx } from "core";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

import { setPeriod, useAppDispatch, useAppSelector } from "_redux";

export enum Period {
  weekly = 7,
  thirty = 30,
  ninety = 90,
  yearly = 365,
  all = 0,
}
export const PeriodMap: Record<Period, NormalizeTranslationKeys> = {
  [Period.weekly]: "header.periods.long.weekly",
  [Period.thirty]: "header.periods.long.thirty",
  [Period.ninety]: "header.periods.long.ninety",
  [Period.yearly]: "header.periods.long.yearly",
  [Period.all]: "header.periods.long.all",
};
export const PeriodAbbreviationMap: Record<Period, NormalizeTranslationKeys> = {
  [Period.weekly]: "header.periods.short.weekly",
  [Period.thirty]: "header.periods.short.thirty",
  [Period.ninety]: "header.periods.short.ninety",
  [Period.yearly]: "header.periods.short.yearly",
  [Period.all]: "header.periods.short.all",
};

interface PeriodControlItemProps {
  period: Period;
  selected: boolean;
  onSelection: (period: Period) => void;
}

const periods: Period[] = [
  Period.weekly,
  Period.thirty,
  Period.ninety,
  Period.yearly,
  Period.all,
];

export const PeriodControl = (): JSX.Element => {
  const period = useAppSelector((state) => state.period.value);
  const dispatch = useAppDispatch();

  const onSelection = (period: Period) => dispatch(setPeriod(period));

  return (
    <div className="flex items-start justify-start gap-2 rounded-[40px] p-0.5">
      {periods.map((periodToMap) => (
        <PeriodControlItem
          key={periodToMap}
          period={periodToMap}
          selected={period === periodToMap}
          onSelection={onSelection}
        />
      ))}
    </div>
  );
};

// 7 J / 30 J / 90 J / 1 AN / TOUT
function PeriodControlItem({
  period,
  selected,
  onSelection,
}: PeriodControlItemProps) {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => onSelection(period)}
      className={clsx(
        "flex h-7 shrink grow basis-0 cursor-pointer items-center justify-center gap-1.5 rounded-2xl text-slate-600",
        selected && "bg-white text-slate-900"
      )}
    >
      <div className="flex h-7 shrink grow basis-0 items-center justify-center gap-1.5 rounded-2xl px-3 py-1.5">
        <div className="text-center text-sm font-bold leading-none">
          {t(PeriodAbbreviationMap[period]) as string}
        </div>
      </div>
    </div>
  );
}
