import { useState, VFC } from "react";
import { useQueryClient } from "react-query";

import { Modal, SorTable, Typo } from "components";
import { addActivityMutation } from "core/queries/mutation";
import { AssetStatus, AssetType, ManualUpdateModalProps } from "@types";
import { activityForm, activityNameForm, updateAssetMutation } from "core";
import { useAppSelector } from "_redux";
import { FieldValues } from "react-hook-form";
import { FormBuilder } from "components/formBuilder";

const useManualAddModal = ({
  selected,
  queryType,
  onClose,
  handleDelete,
}: ManualUpdateModalProps) => {
  const queryClient = useQueryClient();
  const period = useAppSelector((state) => state.period.value);

  const updateName = async (params: any) => {
    try {
      if (!params.id) return;
      const res = await updateAssetMutation({
        params: {
          assetId: params.id,
          name: params.name,
        },
      });

      if (!res) return;
      queryClient.invalidateQueries(["assets-" + queryType]);

      onClose();
    } catch (error) {
      throw new Error(error?.toString() ?? '');
    }
  };
  const onSubmit = async (input: any) => {
    try {
      if (!selected?.asset.id) return;

      const res = await addActivityMutation({
        input,
        assetId: selected.asset.id,
      });

      if (!res) return;
      queryClient.invalidateQueries(["totalWealth", queryType, period]);

      onClose();
    } catch (error) {
      throw new Error(error?.toString() ?? '');
    }
  };
  const onDelete = (id: string) => {
    handleDelete(id);
    onClose();
  };
  const asset = selected?.asset || selected?.estateValues?.asset || {};
  const activities = asset?.activities || [];
  const transactions =
    (queryType === AssetType.bankAccount && asset.transactions) || [];

  const isManual = asset?.status === AssetStatus.manual;

  return {
    activities,
    transactions,
    isManual,
    queryClient,
    period,
    selected,
    queryType,
    updateName,
    onSubmit,
    onDelete,
    onClose,
    handleDelete,
  };
};

export const ManualAddModalWithoutActivity: VFC<ManualUpdateModalProps> = (
  props
) => {
  const { updateName, onSubmit, onDelete, activities, transactions, isManual } =
    useManualAddModal(props);

  const { selected, onClose } = props;

  const [value, setValue] = useState(0);

  if (!selected) return <>No selection</>;

  return (
    <Modal
      open={true}
      setOpen={onClose}
      className="my-modal w-11/12 bg-gray-1200 p-5 lg:max-w-[1300px]"
    >
      {![AssetType.exotic, AssetType.crowdfunding].includes(
        props.queryType as AssetType
      ) ? (
        <FormBuilder
          {...activityNameForm}
          onSubmit={updateName}
          clasName="w-full justify-center"
          defaultValues={{
            id: selected?.asset?.id || selected?.estateValues?.asset?.id,
            name: selected.title,
          }}
          action={({ submit }) => (
            <div className="nom-de-compte">
              <div className="submit-nom-compte">
                <button
                  type="submit"
                  onClick={submit}
                  className="text-secondary button-modal mx-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-100 px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <img
                    src="./verifier.png"
                    alt="Valider nouveau nom de compte"
                  />
                </button>
              </div>
            </div>
          )}
        />
      ) : (
        <p className="pt-4 text-center text-lg font-semibold">
          {selected.title}
        </p>
      )}
      {isManual && (
        <FormBuilder
          clasName="mt-8"
          {...activityForm}
          items={[
            ...activityForm.items,
            {
              children: [
                {
                  type: "custom",
                  name: "exotic",
                  className: "flex-1",
                  element: ({ formValues }: { formValues: FieldValues }) => {
                    const exoticUnitValue =
                      formValues.value > 0
                        ? formValues.value
                        : Number.isNaN(formValues.value)
                        ? 0
                        : selected.price;

                    const value = exoticUnitValue * selected.quantity;
                    setValue(value);

                    return (
                      <div className="flex flex-col pl-5">
                        <Typo
                          type="wlc_inter_s"
                          className="flex w-full flex-col justify-end"
                        >
                          Valeur totale: {exoticUnitValue} x {selected.quantity}
                        </Typo>
                        <Typo
                          type="wlc_inter_xl"
                          className="flex w-full flex-col justify-end"
                        >
                          = {value > 0 && value} €
                        </Typo>
                      </div>
                    );
                  },
                },
              ],
            } as any,
          ]}
          onSubmit={(values) => onSubmit({ ...values, value })}
        />
      )}
      {!isManual && (
        <div className="m-auto mb-7 w-5/6 overflow-hidden rounded-lg border border-gray-200 bg-gray-1400 p-0">
          <div className="flex items-center rounded-t-lg bg-gray-1400 py-3">
            <div className="right-0 mr-3 h-9 w-[6px] rounded-r-lg bg-secondary-500" />
            <Typo type="wlc_inter_l" className="mb-0 text-center">
              Vos transactions
            </Typo>
          </div>
          <SorTable
            type="transactions"
            datas={transactions}
            showLabel
            handleDelete={isManual ? onDelete : undefined}
          />
        </div>
      )}
      <div className="m-auto mb-7 w-5/6 overflow-hidden rounded-lg border border-gray-200 bg-gray-1400 p-0">
        <div className="flex items-center rounded-t-lg bg-gray-1400 py-3">
          <div className="right-0 mr-3 h-9 w-[6px] rounded-r-lg bg-secondary-500" />
          <Typo type="wlc_inter_l" className="mb-0 text-center">
            Votre solde
          </Typo>
        </div>

        <SorTable
          type="amount"
          datas={activities.map((e: any) => ({
            id: e.id,
            amount: e.value,
            date: e.start,
          }))}
          handleDelete={isManual ? props.handleActivityDelete : undefined}
        />
      </div>
    </Modal>
  );
};
