import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RouteListType, routes } from "routing";
import { AssetType } from "@types";
import { useAppSelector } from "_redux";
import { NormalizeTranslationKeys } from "i18n";
import { isAssetRoute } from "core/constants/navigation";
import { tracker, Tracking } from "core/helpers/tracking";
import { Button, Icon, Period, PriceBox, Typo } from "components";
import { rangeForPeriod } from "core/helpers/convinients";
import { HeaderTotalWealthsProps, SidebarProps } from "./sidebar";
import { useFavoriteAssets, useFavoriteAccounts } from "core/helpers/hooks";
import {
  clsx,
  totalWealthUrl,
  headerRealestate,
  getAssetTypeColor,
} from "core";
import { getAssetById } from "screens/private/assetById/components/assets/Assets.logic";

interface HeaderProps extends SidebarProps {
  setIsLoading: (state: boolean) => void;
  setIsEmptyData: (state: boolean) => void;
  setIsFirstFetch: (state: boolean) => void;
}

export function Header({
  params,
  route,
  actif,
  currentRouteKey,
  setIsLoading,
  setSidebarOpen,
  setIsEmptyData,
  setIsFirstFetch,
}: HeaderProps) {
  const period = useAppSelector((state) => state.period.value);

  const type = route.type;
  const isHome = route.key === "home";
  const isRealEstateOrPrivate =
    ("/" + route.key === routes.realEstate.path ||
      route.key === routes.privateCivilRealEstate.key) &&
    route.path != routes.singleRealEstate.path;
  const showTotalWealth = isAssetRoute(route);
  const isWalletAddress = route.key === routes.addWallet.key;
  const isAssetDetailsView = route.key === routes.assetDetails.key;
  const isAssetView = route.path === "/:type/:id";

  const realestateID = params?.estate;
  const realestateQuery = useQuery(
    ["header_realestate", realestateID, period],
    async () => {
      const range = rangeForPeriod(period);
      return headerRealestate(realestateID ?? "N/A", range.start, range.end);
    },
    { enabled: realestateID != undefined }
  );

  const { data = {} } = useQuery(
    ["totalWealth", route.key, period],
    async () => {
      setIsLoading(true);
      const range = rangeForPeriod(period);
      const data = await totalWealthUrl({
        type: showTotalWealth ? null : type,
        start: range.start,
        end: range.end,
        exluding: showTotalWealth ? [AssetType.bankAccount] : null,
      })();

      setIsLoading(false);
      setIsFirstFetch(false);
      setIsEmptyData(
        data?.totalWealth && data?.totalWealth.net === 0 ? true : false
      );

      return data;
    }
  );

  const realestateData =
    realestateID !== undefined && realestateQuery?.data !== null
      ? {
          totalWealth: {
            net: realestateQuery.data?.realEstate.asset.currentActivity.value,
            brut: realestateQuery.data?.realEstate.asset.currentActivity.value,
          },
          totalPerformance: realestateQuery.data?.realEstate.asset.performance,
        }
      : null;

  return (
    <div
      className={clsx(
        "relative top-0 z-_1 flex flex-shrink-0 justify-between rounded-b-lg bg-white p-4 drop-shadow-lg sm:px-6 md_lg:px-8",
        isHome || isRealEstateOrPrivate
          ? "h-40 pt-4 sm:h-[104px]"
          : "xs_sm:pb-15 h-24 items-center p-4"
      )}
    >
      {isAssetView ? (
        <HeaderAssetById
          id={params?.id as string}
          period={period}
          route={route}
        />
      ) : (
        ![routes.addWallet.key, routes.profile.key].includes(route.key) && (
          <HeaderTotalWealths
            data={realestateID !== undefined ? realestateData : data}
            route={route}
            showBrut={showTotalWealth}
            period={period}
          />
        )
      )}
      <Actions
        route={route}
        actif={actif}
        isAssetView={isAssetView}
        currentRouteKey={currentRouteKey}
        showTotalWealth={showTotalWealth}
        isWalletAddress={isWalletAddress}
        isAssetDetailsView={isAssetDetailsView}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
}

function HeaderAssetById({
  id,
  period,
  route,
}: {
  id: string;
  period: Period;
  route: RouteListType;
}) {
  const header = useAppSelector((state) => state.header.value);
  let title = header.title ?? "upsal";

  if (route.title) {
    if (route.key === "asset") {
      title = header.title ?? "...";
    } else {
      title = `title.${route.title}`;
    }
  }

  const range = rangeForPeriod(period);
  const { data } = useQuery(
    ["asset", id, period],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getAssetById(id!, range.start, range.end)
  );

  return (
    <div className="flex w-full flex-row gap-x-4">
      {header.icon && (
        <img
          src={header.icon}
          alt="icon"
          className="ml-4 h-16 w-16 self-center rounded-full bg-[#F6F4F3] p-4"
        />
      )}
      <PriceBox
        showToggler
        title={title}
        type={route.type}
        showBrut={false}
        net={data?.currentActivity.value ?? 0}
        brut={data?.currentActivity.value ?? 0}
        evolution={data?.performance?.evolution ?? 0}
        icon="wc_dollar"
        {...({} as any)}
      />
      <div className="plus-value ml-4">
        <PriceBox
          {...data?.performance}
          showGain
          type={route.type}
          period={period}
          {...({} as any)}
        />
      </div>
    </div>
  );
}

function HeaderTotalWealths({
  data,
  route,
  showBrut,
  period,
}: HeaderTotalWealthsProps) {
  const header = useAppSelector((state) => state.header.value);
  let title = header.title ?? "upsal";

  if (route.title) {
    if (route.key === "asset") {
      title = header.title ?? "...";
    } else {
      title = `title.${route.title}`;
    }
  }

  return (
    <div className="flex w-full flex-row gap-x-8">
      <PriceBox
        showToggler
        title={title}
        type={route.type}
        showBrut={showBrut}
        {...data?.totalWealth}
        icon="wc_dollar"
      />
      {route.key !== "loan" && (
        <div className="plus-value">
          <PriceBox
            {...(route.key === "home"
              ? data.homeEvolution
              : data?.totalPerformance)}
            showGain
            type={route.type}
            period={period}
          />
        </div>
      )}
    </div>
  );
}

function Actions({
  actif,
  route,
  showTotalWealth,
  currentRouteKey,
  isWalletAddress,
  isAssetDetailsView,
  setSidebarOpen,
}: Pick<
  HeaderProps,
  "route" | "actif" | "currentRouteKey" | "isWalletAddress" | "setSidebarOpen"
> & {
  isAssetView: boolean;
  isAssetDetailsView: boolean;
  showTotalWealth: boolean;
}) {
  const { t } = useTranslation();
  const onAddWalletClick = () => tracker.log(Tracking.Event.CTA_ADD_WALLET);
  const {
    isLoading: isFavoriteLoading,
    isFavorite: isFavoriteAssets,
    addFavorite: addFavoriteAssets,
    removeFavorite: removeFavoriteAssets,
  } = useFavoriteAssets();
  const {
    isLoading: isFavoriteAccountLoading,
    isFavorite: isFavoriteAccount,
    addFavorite: addFavoriteAccount,
    removeFavorite: removeFavoriteAccount,
  } = useFavoriteAccounts();
  const params = useParams() as {
    id?: string;
    type?: AssetType;
  };

  const isAccount = isAssetDetailsView && params.type === AssetType.bankAccount;

  const isFavorite =
    (isAccount && isFavoriteAccount(params.id as string)) ||
    (!isAccount && isFavoriteAssets(route.type as AssetType));
  const isLoading = isFavoriteLoading || isFavoriteAccountLoading;
  const showFavorite = !showTotalWealth;
  const isProfile = route.key === routes.profile.key;

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {showFavorite && (
            <div
              onClick={() => {
                if (isLoading) return;

                if (isAccount) {
                  if (isFavorite) {
                    removeFavoriteAccount(params.id as string);
                  } else {
                    addFavoriteAccount(params.id as string);
                  }
                } else {
                  if (isFavorite) {
                    removeFavoriteAssets(route.type);
                  } else {
                    addFavoriteAssets(route.type);
                  }
                }
              }}
            >
              <Icon
                type="pin"
                className={clsx(
                  "mr-4 h-7 w-7 cursor-pointer",
                  isFavorite ? "fill-asset stroke-asset" : "stroke-[#161B33]",
                  isLoading && "opacity-50"
                )}
              />
            </div>
          )}
          {!isWalletAddress && !isProfile && (
            <div className="flex justify-end">
              <div
                className="relative flex items-center"
                onClick={onAddWalletClick}
              >
                <>
                  <Button
                    icon="wc_addWallet"
                    label="actions.add_wallet"
                    to={`${routes.addWallet.path}?actif=${
                      !currentRouteKey || !showFavorite
                        ? "home"
                        : params.type ?? currentRouteKey
                    }`}
                    rounded
                    className="yellow-dropshadow ajouter-actif ac-hover mx-0 hidden bg-asset px-4 py-3 font-semibold text-white md:text-lg md_lg:flex"
                  />
                  <Button
                    icon="wc_addWallet"
                    to={`${routes.addWallet.path}?actif=${
                      currentRouteKey === routes.privateCivilRealEstate.key
                        ? "realEstate"
                        : currentRouteKey
                    }`}
                    rounded
                    className="mx-_1 flex gap-x-0 bg-asset md_lg:hidden"
                  />
                </>
              </div>
            </div>
          )}
        </div>
        <button
          type="button"
          className="h-12 px-2 text-gray-500 sm:px-4 md_lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Icon type="burger" className="h-6 w-6" />
        </button>
      </div>
      {isWalletAddress && (
        <div className="flex w-full justify-center">
          <Typo
            type="wlc_inter_xl"
            children={
              <>
                {t("actions.add")}{" "}
                <span
                  style={{
                    color: getAssetTypeColor(actif as AssetType)?.color,
                  }}
                >
                  {actif &&
                    (t(`title.${actif}` as NormalizeTranslationKeys) as string)}
                </span>
              </>
            }
            className="text-center"
          />
        </div>
      )}
      {isProfile && (
        <div className="flex w-full justify-center">
          <Typo type="wlc_inter_xl" children="Profil" className="text-center" />
        </div>
      )}
    </>
  );
}
