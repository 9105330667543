import { MouseEvent, VFC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Icon } from "./Icon";
import { routes } from "routing";
import { RadioSelect } from "./RadioSelect";
import { FormBuilder } from "components/formBuilder";
import i18n, { NormalizeTranslationKeys } from "i18n";
import { AuthStrategy, FormItemChildrenType } from "@types";

export type AuthFormType = "login" | "register" | "forgot-password";
const emailSchema: FormItemChildrenType<NormalizeTranslationKeys> = {
  name: "email",
  type: "email",
  label: "forms.auth.email.label",
  placeholder: "forms.auth.email.placeholder",
};
const passwordSchema: FormItemChildrenType<NormalizeTranslationKeys> = {
  name: "password",
  type: "password",
  label: "forms.auth.password.label",
  placeholder: "forms.auth.password.placeholder",
};

const formTypes: Record<
  AuthFormType,
  FormItemChildrenType<NormalizeTranslationKeys>[]
> = {
  login: [emailSchema, passwordSchema],
  register: [
    emailSchema,
    passwordSchema,
    {
      name: "confirmPassword",
      type: "password",
      label: "forms.auth.confirmPassword.label",
      placeholder: "forms.auth.confirmPassword.placeholder",
    },
  ],
  "forgot-password": [emailSchema],
};
interface Props {
  isLogin: boolean;
  navigate: ReturnType<typeof useNavigate>;
  pageType: AuthFormType;
  discountCode?: string;
  isForgotPassword: boolean;
  handleAuthWithForm: ({ rememberMe, ...state }: any) => Promise<void>;
  handleAuthWithStrategy: (strategy: AuthStrategy) => () => Promise<void>;
}

export const AuthComponent: VFC<Props> = ({
  pageType,
  discountCode,
  isLogin,
  isForgotPassword,
  navigate,
  handleAuthWithForm,
  handleAuthWithStrategy,
}) => {
  const { t } = useTranslation();

  return (
    <div className="login flex flex-col items-center overflow-visible px-5 py-6 sm_md:flex-row md_lg:overflow-hidden xl_2xl:h-auto xl_2xl:px-0 xl_2xl:py-0">
      <ChangeLanguage />
      <div className="hidden lg:w-1/2 xl_2xl:block">
        <Link to={routes.login.path}>
          <img
            className="lg:max-w-inherit h-screen max-w-full object-cover"
            src="./svg/image-login.svg"
            alt="Welcome to Wealthcome"
          />
        </Link>
      </div>
      <div className="w-full py-12 md_lg:py-0 xl_2xl:w-1/2">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to={routes.login.path}>
            <img
              className="mx-auto w-[200px]"
              src="wc_logo.png"
              alt="wealthcome"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">
            {isForgotPassword
              ? t("forms.auth.changePassword")
              : isLogin
              ? t("forms.auth.login")
              : t("forms.auth.register")}
          </h2>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="flex flex-col px-2 text-gray-500">
                <button
                  onClick={() =>
                    pageType === "login"
                      ? navigate(routes.register.path)
                      : navigate(routes.login.path)
                  }
                  className="rounded bg-primary-100 px-2 py-1 font-medium text-primary-400"
                >
                  {t("forms.auth.or")}{" "}
                  {isLogin ? t("forms.auth.register") : t("forms.auth.login")}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="rounded-md bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
            <FormBuilder
              defaultRequired
              clasName="login-form"
              onSubmit={handleAuthWithForm}
              defaultValues={{ discountCode }}
              items={[
                {
                  className: "grid grid-cols-1",
                  // children: formTypes[pageType],
                  children: [
                    ...formTypes[pageType],
                    ...((discountCode
                      ? [
                          {
                            name: "discountCode",
                            type: "text",
                            label: "Code partenaire",
                          },
                        ]
                      : []) as FormItemChildrenType[]),
                  ],
                },
                {
                  className: "grid grid-cols-1 items-center",
                  children: [
                    ...((isLogin
                      ? [
                          {
                            name: "forgotPassword",
                            type: "custom",
                            className: "pr-4 mdp-oublie place-content-center",
                            element: () => (
                              <a
                                href="https://www.wealthcome.fr/login/?action=forgot_password"
                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                  e.preventDefault();
                                  navigate(routes.forgotPassword.path);
                                }}
                                className="font-medium text-primary-500 hover:text-primary-400"
                              >
                                {t("forms.auth.forgotPassword")}
                              </a>
                            ),
                          },
                        ]
                      : []) as FormItemChildrenType[]),
                  ],
                },
                {
                  className: "grid grid-cols-1 items-center",
                  children: [
                    ...((pageType === "register"
                      ? [
                          {
                            name: "newsletter",
                            type: "checkbox",
                            options: [
                              {
                                label: t("forms.auth.newsletter"),
                                name: "newsletter",
                                className: "m-0",
                              },
                            ],
                          },
                          {
                            name: "cgupolitique",
                            type: "checkbox",
                            options: [
                              {
                                label: (
                                  <>
                                    {t("forms.auth.terms.accept")}{" "}
                                    <a
                                      href="https://www.wealthcome.fr/cgu"
                                      target="_bank"
                                      className="underline"
                                    >
                                      {t("forms.auth.terms.generalConditions")}
                                    </a>{" "}
                                    {t(
                                      "forms.auth.terms.generalConditionsRemain"
                                    )}{" "}
                                    <a
                                      href="https://www.wealthcome.fr/politique-de-confidentialite"
                                      target="_bank"
                                      className="underline"
                                    >
                                      {t("forms.auth.terms.confidentiality")}
                                    </a>
                                  </>
                                ),
                                name: "cgupolitique",
                                className: "m-0",
                              },
                            ],
                          },
                        ]
                      : []) as FormItemChildrenType[]),
                  ],
                },
              ]}
            >
              {({ submit }) => (
                <button
                  type="submit"
                  onClick={submit}
                  className="flex w-full justify-center rounded-md bg-gradient-to-t from-primary-1100 to-primary-1000 px-4 py-2 text-sm font-medium text-white shadow-sm"
                >
                  {
                    t(
                      `forms.auth.${
                        isLogin
                          ? "connect"
                          : isForgotPassword
                          ? "change"
                          : "register"
                      }`
                    ) as NormalizeTranslationKeys
                  }
                </button>
              )}
            </FormBuilder>
            {!isForgotPassword && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-primary-200" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      {t("forms.auth.orContinueWith")}
                    </span>
                  </div>
                </div>

                <div>
                  <div>
                    <a
                      href="#"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-primary-200 bg-white px-2 py-1 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                      onClick={handleAuthWithStrategy("google")}
                    >
                      <div className="flex w-full flex-row place-content-center items-center">
                        <img
                          className="h-10 w-10"
                          src="./svg/google.svg"
                          alt="Sign in with Google"
                        />
                        <span>Google</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function ChangeLanguage() {
  const lang = i18n.language;

  const changeLang = async (lng: string) => {
    await i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  return (
    <div className="fixed right-[22px] top-[22px] shadow-md">
      <div className="group relative">
        <div className="flex items-center gap-1">
          <Icon type={`${lang}-flag`} className="h-6" />
        </div>
        <RadioSelect
          position="bottom"
          align="right"
          defaultValue={lang}
          list={[
            {
              element: <Icon type="fr-flag" className="h-6" />,
              id: "fr",
            },
            {
              element: <Icon type="en-flag" className="h-6" />,
              id: "en",
            },
            {
              element: <Icon type="es-flag" className="h-5" />,
              id: "es",
            },
            {
              element: <Icon type="de-flag" className="h-5" />,
              id: "de",
            },
          ]}
          onChange={changeLang}
          className="w-28"
        />
      </div>
    </div>
  );
}
