import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { Icon } from "./Icon";
import { NormalizeTranslationKeys } from "i18n";

export interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: any) => void;
}

export function Container({ children, className, onClick }: ContainerProps) {
  return (
    <div
      className={clsx(
        "flex h-fit flex-col rounded-xl bg-white p-4 drop-shadow-xl",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

interface ScrollableContainerProps extends ContainerProps {
  title: NormalizeTranslationKeys;
  SubHead?: React.ReactNode;
  Right?: React.ReactNode;
  children: React.ReactNode;
  hideContent?: boolean;
  onTitleClick?: () => void;
  height?: number | "auto";
}
export function ScrollableContainer({
  title,
  Right,
  SubHead,
  children,
  className,
  height = 50,
  hideContent = false,
  onClick,
  onTitleClick,
}: ScrollableContainerProps) {
  const { t } = useTranslation();

  return (
    <Container
      className={clsx("h-full items-stretch p-0", className)}
      onClick={onClick}
    >
      <div className="p-4">
        <div className="flex items-center justify-between pb-0 font-sans text-lg font-bold">
          <h2
            className={clsx(
              onTitleClick
                ? "flex cursor-pointer items-center text-sm font-medium leading-none text-slate-600"
                : ""
            )}
            onClick={onTitleClick}
          >
            {t(title) as string}
            {onTitleClick && (
              <Icon
                type="wc_arrowUp"
                className="fill-arrow-menu "
                rotate={90}
              />
            )}
          </h2>
          {Right}
        </div>
        {SubHead}
      </div>
      {!hideContent && (
        <div
          className="overflow-y-scroll"
          style={
            height === "auto"
              ? { height: "auto" }
              : {
                  height: `calc(${height}vh - 160px)`,
                }
          }
        >
          {children}
        </div>
      )}
    </Container>
  );
}
