import { useState, VFC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Container, Icon } from "components";
import { Content } from "./content";
import { PanelList } from "./PanelList";
import { AssetType, Page } from "@types";
import { AuthService } from "core/services";
import { routes, RoutesType } from "routing";
import { useGQLQuery } from "core/queries/useQuery";
import { pick, separete } from "core/helpers/object";
import { tracker, Tracking } from "core/helpers/tracking";
import { rangeForPeriod } from "core/helpers/convinients";
import { ChevronRightIcon } from "@heroicons/react/solid";
import {
  manualForm,
  realEstatesUrl,
  ManualFormFields,
  budgetInsightUrl,
  manualFormCRUDHandler,
} from "core";

export const AddWallet: VFC<Page> = ({ currentRouteKey, querySearch }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [type, setType] = useState(querySearch?.get("actif") as RoutesType);
  const [estate, setEstate] = useState<RoutesType | null>(null);
  const { data = {} } = useGQLQuery(["BiUrl"], budgetInsightUrl);
  const range = rangeForPeriod(0);
  const { data: realEstates = {} } = useGQLQuery(type, realEstatesUrl(range));
  const route = routes[type];
  const isStocksOrCrypto = type === AssetType.stocks || type === "crypto";
  const { t } = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line prefer-const
  let { formFormatter, ...formParams } =
    manualForm[type] || ({} as ManualFormFields);

  if (!currentRouteKey) return <>Form not found for this route</>;

  const user = AuthService.getUser();
  const isPremium = user?.isPremium;
  const cgpClient = ["pro_simple", "pro_premium"];
  const is_cgp_client = user?.plan ? cgpClient.includes(user.plan) : false;

  const redirectTo = (type: RoutesType) => () => {
    setType(type);
    navigate({
      search: `?actif=${type}`,
    });
    tracker.log(Tracking.AssetTypeEvent.CTA_ADD_WALLET, type as AssetType);
  };

  const [isCardManual, setIsCardManual] = useState(false);

  const toggle = () => {
    const showManual = !isCardManual;

    setIsCardManual(showManual);

    tracker.log(
      Tracking.AssetTypeEvent[
        showManual ? "CTA_ADD_MANUAL" : "CTA_ADD_AUTOMATIC"
      ],
      type as AssetType
    );
  };

  const onSubmit = async (formValues: any) => {
    try {
      let variables = {};

      if (isSubmit) return;

      setIsSubmit(true);

      const { create } = manualFormCRUDHandler[estate ?? type] || {};

      if (estate === "privateCivilRealEstate") {
        const { name, ...input } = formValues;

        variables = {
          input: {
            ...input,
            name: name?.value,
          },
        };
      } else {
        variables = {
          ...formValues,
          activity: pick(formValues, ["value", "date", "instrument"]),
        };

        if (isStocksOrCrypto) {
          const [platform, input] = separete(formValues, ["platformId"]);
          variables = {
            input: {
              ...input,
              ...(type === AssetType.stocks
                ? { valuation: input.unitPrice * input.quantity }
                : {}),
            },
            platformId: platform.platformId,
          };
        } else if (formFormatter) variables = formFormatter(formValues);
      }

      const res = await create?.(variables);
      if (res && routes[estate ?? type])
        window.location.href = routes[estate ?? type].path;
      else setIsSubmit(false);
    } catch (error) {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-6 md:p-10">
      {type === "home" ? (
        <PanelList action={redirectTo} />
      ) : type != "privateCivilRealEstate" &&
        type != "realEstate" &&
        type != "exotic" ? (
        <>
          <div className="mb-10 flex w-full flex-col justify-between gap-x-16 gap-y-4 px-0 md:flex-row xl:px-18">
            <Container
              className="group flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-5"
              onClick={toggle}
            >
              <div className="flex w-full items-center justify-between gap-x-2">
                <h3 className="flex gap-2 text-lg font-bold leading-normal text-slate-600">
                  {t("actions.manual")}
                </h3>
                <div className="w-fit">
                  <Icon type="arrow" />
                </div>
              </div>
            </Container>
            {user?.plan !== "pro_simple" && (
              <Container className="group flex w-full cursor-pointer items-center justify-between rounded-xl p-0">
                <a
                  href={data.budgetInsightWebview}
                  onClick={toggle}
                  className="block w-full rounded-xl p-5 "
                >
                  <div className="flex w-full items-center justify-between gap-x-2">
                    <h3 className="flex gap-2 text-lg font-bold leading-normal text-slate-600">
                      {t("actions.automatic")}
                      <Icon type="premium_logo" />
                    </h3>
                    <div className="w-fit">
                      <Icon type="arrow" />
                    </div>
                  </div>
                </a>
              </Container>
            )}
          </div>
          {isCardManual && (
            <Content
              type={type}
              biUrl={data.budgetInsightWebview}
              isStocksOrCrypto={isStocksOrCrypto}
              formParams={formParams}
              onSubmit={onSubmit}
              redirectTo={redirectTo}
              icon={route?.menuIcon}
            />
          )}
        </>
      ) : type === "exotic" ? (
        <>
          <div className="mb-10 flex w-full flex-col justify-between gap-x-16 gap-y-4 px-0 md:flex-row xl:px-18">
            <div
              className="group flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-5 drop-shadow-md md:w-1/2"
              onClick={toggle}
            >
              <div className="flex items-center gap-x-2">
                <h3 className="font-bold tracking-tighter text-neutral-800 group-hover:text-primary">
                  Manuellement
                </h3>
                <div className="w-fit">
                  <ChevronRightIcon className="h-5 w-5 text-neutral-800" />
                </div>
              </div>
            </div>
          </div>
          {isCardManual ? (
            <Content
              type={type}
              biUrl={data.budgetInsightWebview}
              isStocksOrCrypto={isStocksOrCrypto}
              formParams={formParams}
              onSubmit={onSubmit}
              redirectTo={redirectTo}
              icon={route?.menuIcon}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <div className="mb-10 flex w-full flex-col justify-between gap-x-16 gap-y-4 px-0 md:flex-row xl:px-18">
            {isPremium === false &&
            !is_cgp_client &&
            realEstates?.realEstates?.length > 0 ? (
              <a
                className="group flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-5 drop-shadow-md md:w-1/2"
                href={routes.profile.path + "#abonner-premium"}
              >
                <div className="flex w-full items-center justify-between gap-x-2">
                  <div>
                    <h3 className="font-bold tracking-tighter text-neutral-800 group-hover:text-primary">
                      Immobilier
                    </h3>
                    <p className="text-xs font-medium text-primary">
                      Abonnez-vous à Wealthcome Premium pour pouvoir
                      synchroniser d'autres biens
                    </p>
                  </div>
                  <div className="w-fit">
                    <ChevronRightIcon className="h-5 w-5 text-neutral-800" />
                  </div>
                </div>
              </a>
            ) : (
              <div
                className="group flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-5 drop-shadow-md md:w-1/2"
                onClick={() => setEstate("realEstate")}
              >
                <div className="flex w-full items-center justify-between gap-x-2">
                  <h3 className="font-bold tracking-tighter text-neutral-800 group-hover:text-primary">
                    Immobilier
                  </h3>
                  <div className="w-fit">
                    <ChevronRightIcon className="h-5 w-5 text-neutral-800" />
                  </div>
                </div>
              </div>
            )}
            <div
              className="group flex w-full cursor-pointer items-center justify-between rounded-xl bg-white p-5 drop-shadow-md md:w-1/2"
              onClick={() => setEstate("privateCivilRealEstate")}
            >
              <div className="flex w-full items-center justify-between gap-x-2">
                <h3 className="font-bold tracking-tighter text-neutral-800 group-hover:text-primary">
                  SCPI
                </h3>
                <div className="w-fit">
                  <ChevronRightIcon className="h-5 w-5 text-neutral-800" />
                </div>
              </div>
            </div>
          </div>
          {!!estate && (
            <Content
              type={estate}
              biUrl={data.budgetInsightWebview}
              isStocksOrCrypto={false}
              formParams={formParams}
              onSubmit={onSubmit}
              redirectTo={redirectTo}
              icon={route?.menuIcon}
            />
          )}
        </>
      )}
    </div>
  );
};
