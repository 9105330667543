import { useState } from "react";

import { clsx } from "core";
import { Icon } from "./Icon";
import { Container } from "./Container";

type ID = string;
type Item = {
  id: ID;
  element: JSX.Element | string;
};

interface RadioSelectProps {
  list: Item[];
  type?: "radio" | "select";
  hideIcon?: boolean;
  defaultValue?: ID;
  className?: string;
  align?: "left" | "right";
  position?: "top" | "bottom";
  onChange: (value: ID) => void;
}

export function RadioSelect({
  list = [],
  defaultValue,
  onChange,
  className,
  hideIcon = false,
  align = "left",
  position = "bottom",
}: RadioSelectProps) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (id: ID) => {
    setValue(id);
    onChange(id);
  };

  if (list.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        "absolute inset-0 bottom-4 z-50 hidden h-fit w-full translate-y-full flex-col rounded-md pt-4 uppercase group-hover:flex",
        position === "top" && "top-4 -translate-y-full pb-4 pt-0",
        align === "right" && "right-0",
        className
      )}
    >
      <Container className="border border-primary-300 p-1">
        {list.map((item) => {
          const isCurrent = item.id === value;

          return (
            <div
              key={item.id}
              className={clsx(
                "brut-hover flex w-full cursor-pointer items-center justify-between rounded-md px-1 py-1 text-xs font-semibold text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                isCurrent
                  ? "tex-primary-400 bg-primary-soft shadow"
                  : "text-primary-200 duration-150 ease-in"
              )}
              onClick={() => handleChange(item.id)}
            >
              {item.element}
              {!hideIcon && isCurrent && <Icon type="check" className="w-3" />}
            </div>
          );
        })}
      </Container>
    </div>
  );
}
