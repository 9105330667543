import { createContext, useContext, FC } from "react";

import { FormFieldsVariant } from "@types";

type ClassNameTypes = {
  typo?: {
    wlc_inter_xxl?: string;
    wlc_inter_xl?: string;
    wlc_inter_l?: string;
    wlc_inter_underline_l?: string;
    wlc_inter_m?: string;
    wlc_inter_sm?: string;
    wlc_inter_s?: string;
    wlc_inter_underline_s?: string | undefined;
  };
  form?: {
    className: string | undefined;
  };
  input?: {
    wrapper?: string | undefined;
    container?: string | undefined;
    className?: string | undefined;
  };
  checkbox?: {
    wrapper?: string | undefined;
    checkboxContainer?: string | undefined;
    checkbox?: string | undefined;
    labelContainer?: string | undefined;
    label?: string | undefined;
  };
  label?: {
    className: string | undefined;
  };
  legend?: {
    className: string | undefined;
  };
  action?: {
    className: string | undefined;
  };
};
interface FormBuilderProviderProps {
  variant?: FormFieldsVariant;
  classNames?: ClassNameTypes;
  children?: React.ReactNode;
}
const FormBuilderContext = createContext<FormBuilderProviderProps>({});

export const FormBuilderProvider: FC<FormBuilderProviderProps> = ({
  variant = "standard",
  children,
  classNames,
}) => {
  return (
    <FormBuilderContext.Provider
      value={{
        variant,
        classNames: {
          typo: {
            wlc_inter_xxl: classNames?.typo?.wlc_inter_xxl,
            wlc_inter_xl: classNames?.typo?.wlc_inter_xl,
            wlc_inter_l: classNames?.typo?.wlc_inter_l,
            wlc_inter_underline_l: classNames?.typo?.wlc_inter_underline_l,
            wlc_inter_m: classNames?.typo?.wlc_inter_m,
            wlc_inter_s: classNames?.typo?.wlc_inter_s,
            wlc_inter_underline_s: classNames?.typo?.wlc_inter_underline_s,
          },
          form: {
            className: classNames?.form?.className || "",
          },
          input: {
            wrapper: classNames?.input?.wrapper || "",
            container: classNames?.input?.container || "",
            className: classNames?.input?.className || "",
          },
          label: {
            className: classNames?.label?.className || "",
          },
          legend: {
            className: classNames?.legend?.className || "",
          },
          action: {
            className: classNames?.action?.className || "",
          },
        },
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

export const useFormBuilder = () => useContext(FormBuilderContext);
