import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";
import { memo, useEffect, useState } from "react";

import { NormalizeTranslationKeys } from "i18n";
import { FormSelectProps, Option } from "@types";
import { selectComponents } from "core/helpers/form";
import { FormLabel } from "components/form/FormLabel";

interface AsyncReactSelectType
  extends FormSelectProps<NormalizeTranslationKeys> {
  loadOptions: () => Promise<Option[]>;
}
export const AsyncReactSelect = memo(
  ({
    name,
    label,
    control,
    required,
    defaultValue,
    placeholder,
    className = "w-full",
    setValue,
    onSearch,
    onChange,
    loadOptions,
  }: AsyncReactSelectType) => {
    const [options, setOptions] = useState<Option[]>([]);
    const { t } = useTranslation();

    const { field } = useController({
      name,
      control,
      defaultValue: defaultValue,
      rules: { required },
    });

    const handleSearch = async (
      value: string,
      callback: (options: any[]) => void
    ) => {
      let newOptions: Option[] = [];
      if (!onSearch) return;
      // check if onSearch is a promise or not
      if (!(onSearch instanceof Promise))
        newOptions = await onSearch(value, options, {
          form: {
            setValue,
          },
        });
      else
        newOptions = onSearch(value, options, {
          form: {
            setValue,
          },
        }) as Option[];

      callback(newOptions);
      setOptions(newOptions);
    };
    const handleChange = (value: Option) => {
      field.onChange(value);
      onChange && onChange(value, { form: { setValue } });
    };

    useEffect(() => {
      if (!loadOptions) return;
      const effect = async () => {
        try {
          const options = (await loadOptions()) || [];

          setOptions(options);
        } catch (error) {
          throw new Error(error?.toString() ?? '');
        }
      };
      effect();
    }, [defaultValue, loadOptions]);

    return (
      <>
        {!!label && <FormLabel name={name} label={label} />}
        <AsyncSelect
          name={name}
          isClearable
          isSearchable
          defaultOptions
          className={className}
          components={selectComponents}
          loadOptions={handleSearch as any}
          onChange={(value) => handleChange(value as Option)}
          defaultValue={{ label: defaultValue, value: "value" }}
          placeholder={
            placeholder
              ? t(placeholder as NormalizeTranslationKeys).toString()
              : undefined
          }
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              overflow: "hidden",
              borderRadius: 16,
              border: "0 !important",
              boxShadow: "0 !important",
              "&:hover": {
                border: "0 !important",
              },
              "*": {
                boxShadow: "none !important",
              },
            }),
          }}
        />
      </>
    );
  },
  (prevProps, nextProps) => prevProps.name === nextProps.name
);
