import { AuthStrategy } from "@types";
import {
  signInWithPopup,
  getAuth,
  GoogleAuthProvider,
  signOut,
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { firebaseApp } from "lib";

class _AuthService {
  auth: Auth = getAuth(firebaseApp);

  async authWithCredentials(email: string, password: string) {
    if (!this.auth) return;
    const result = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const idToken = await result.user?.getIdToken();

    return { user: result.user, idToken };
  }
  async authCreateWithCredentials(email: string, password: string) {
    if (!this.auth) return;
    const result = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const idToken = await result.user?.getIdToken();

    return { user: result.user, idToken };
  }
  async authWithStrategy(strategy: AuthStrategy) {
    switch (strategy) {
      case "google":
        return await this.loginWithGoogle();
      default:
        return;
    }
  }
  private async loginWithGoogle() {
    if (!this.auth) return;
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(this.auth, provider);
    // get idToken
    const idToken = await result.user?.getIdToken();

    return { user: result.user, idToken };
  }
  async resetPassword(email: string) {
    if (!this.auth) return;
    await sendPasswordResetEmail(this.auth, email);
  }

  async logout() {
    await signOut(this.auth);
  }
}

export const firebaseAuthService = new _AuthService();
