import { FC } from "react";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { TypoProps } from "@types";
import { isStr } from "core/helpers/check";
import { NormalizeTranslationKeys } from "i18n";

const typeDefaults = {
  wlc_inter_xxl: "font-semibold text-gray-800 _700:text-_28 text-_26",
  wlc_inter_xl: "font-semibold text-gray-800 _700:text-_21 text-_19",
  wlc_inter_l:
    "font-semibold text-gray-800 xs_sm:text-_18 _700:text-_16 text-_14",
  wlc_inter_underline_l:
    "font-semibold text-gray-800 _700:text-_16 text-_14 underline",
  wlc_inter_m: "font-medium text-gray-800 _700:text-_16 text-_14",
  wlc_inter_sm: "font-medium text-gray-800 text-_14",
  wlc_inter_s: "font-medium text-gray-800 text-_12",
  wlc_inter_underline_s: "font-medium text-gray-800 text-_12 underline",
};

interface UseTypoProps extends TypoProps {
  children?: React.ReactNode;
}

const useTypo = ({
  children,
  style,
  type,
  i18nVars,
  onClick,
  ...props
}: UseTypoProps) => {
  const { t } = useTranslation();

  return {
    type,
    className: clsx(type && typeDefaults[type], props.className),
    content: isStr(children)
      ? (t(children as NormalizeTranslationKeys, i18nVars) as string)
      : children,
    style,
    onClick,
  };
};

export const Typo: FC<TypoProps> = (props) => {
  const { type, content, ...rest } = useTypo(props);

  switch (type) {
    case "wlc_inter_xxl":
      return <h1 {...rest}>{content}</h1>;
    case "wlc_inter_xl":
      return <h2 {...rest}>{content}</h2>;
    case "wlc_inter_l":
      return <h3 {...rest}>{content}</h3>;
    case "wlc_inter_underline_l":
      return <h4 {...rest}>{content}</h4>;
    case "wlc_inter_m":
      return <p {...rest}>{content}</p>;
    case "wlc_inter_underline_s":
      return <p {...rest}>{content}</p>;
    default:
      return <p {...rest}>{content}</p>;
  }
};
