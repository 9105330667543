import { type FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, FormLabel } from "components";
import { setUserInformationsGeneralMutation } from "core";
import { useMutation, useQueryClient } from "react-query";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/RadixAccordion";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { CustomInput } from "components/CustomInput";
import { selectComponents } from "core/helpers/form";
import CreatableSelect from "react-select/creatable";
import { Option } from "@types";

type InputProps = {
  type: "text" | "date" | "number" | "file";
  step?: number;
};

const maritalStatusFormData = {
  lastName: {
    type: "text",
  },
  birthName: {
    type: "text",
  },
  firstName: {
    type: "text",
  },
  birthDate: {
    type: "date",
  },
  birthCity: {
    type: "text",
  },
  birthCountry: {
    type: "text",
  },
  familySituation: {
    type: "text",
  },
  nationality: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const professionnalSituationFormData = {
  studiesLevel: {
    type: "text",
  },
  occupation: {
    type: "text",
  },
  occupationWording: {
    type: "text",
  },
  retirementAge: {
    type: "number",
  },
  annualIncome: {
    type: "number",
    step: 0.01,
  },
  globalHeritage: {
    type: "number",
  },
  heritageOrigin: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const identityFormData = {
  seniorAdvisor: {
    type: "text",
  },
  dateEntryRelationship: {
    type: "date",
  },
  // idCard: {
  //   type: "file",
  // },
  idNumber: {
    type: "text",
  },
  issueDate: {
    type: "date",
  },
  authority: {
    type: "text",
  },
  expirationDate: {
    type: "date",
  },
} satisfies Record<string, InputProps>;

const otherInformationsFormData = {
  idMIF: {
    type: "text",
  },
  taxResidenceCountry: {
    type: "text",
  },
  customerClassificationMIF: {
    type: "text",
  },
  classificationDate: {
    type: "date",
  },
  pep: {
    type: "text",
  },
  usPerson: {
    type: "text",
  },
  legalCapacity: {
    type: "number",
  },
} satisfies Record<string, InputProps>;

export type GeneralInformationsFormDataType = Record<
  keyof typeof maritalStatusFormData,
  string | number
> &
  Record<keyof typeof professionnalSituationFormData, string | number> &
  Record<keyof typeof identityFormData, string | number> &
  Record<keyof typeof otherInformationsFormData, string | number>;

export const ProfileInformationsGeneral: FC<{ defaultValues?: object }> = ({
  defaultValues,
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (input: Record<string, any>) =>
      setUserInformationsGeneralMutation({ input }),
    {
      onSuccess: () => {
        toast.success(t("notification.save"));
        queryClient.invalidateQueries("user");
      },
    }
  );

  const { t } = useTranslation();

  const form = useForm<GeneralInformationsFormDataType>({
    defaultValues,
  });

  const maritalStatus =
    t("forms.customers.details.general.maritalStatus", {
      returnObjects: true,
    }) || {};
  const maritalStatusOptions: Option[] = Object.entries(maritalStatus).map(
    ([value, label]: [string, string]) => ({
      value,
      label,
    })
  );

  const countries = t("countries", { returnObjects: true }) || {};
  const countriesOptions: Option[] = Object.entries(countries).map(
    ([value, label]: [string, string]) => ({
      value,
      label,
    })
  );

  const heritageOrigins =
    t("forms.customers.details.general.heritageOriginList", {
      returnObjects: true,
    }) || {};
  const heritageOriginsOptions: Option[] = Object.entries(heritageOrigins).map(
    ([value, label]: [string, string]) => ({
      value,
      label,
    })
  );

  const legalCapacityOptions: Option[] = [
    { label: "Aucune", value: "Aucune" },
    {
      label: "Administrateur Ad Hoc Art. 455 et 456",
      value: "Administrateur Ad Hoc Art. 455 et 456",
    },
    {
      label: "Administration Légale Art. 389 & suiv.",
      value: "Administration Légale Art. 389 & suiv.",
    },
    {
      label: "Ancienne Tutelle d'état Art. 411",
      value: "Ancienne Tutelle d'état Art. 411",
    },
    {
      label: "Ancienne Tutelle en gérance",
      value: "Ancienne Tutelle en gérance",
    },
    {
      label: "Curatelle Anc. Art 511",
      value: "Curatelle Anc. Art 511",
    },
    {
      label: "Curatelle Anc. Art 512",
      value: "Curatelle Anc. Art 512",
    },
    {
      label: "Curatelle Art. 440 & suiv.",
      value: "Curatelle Art. 440 & suiv.",
    },
    {
      label: "Curatelle Renforcée Art. 472",
      value: "Curatelle Renforcée Art. 472",
    },
    {
      label: "Habilitation Familiale Assistance Art.494-1 & suiv.",
      value: "Habilitation Familiale Assistance Art.494-1 & suiv.",
    },
    {
      label: "Habilitation Familiale Représentation Art.494-1 & suiv",
      value: "Habilitation Familiale Représentation Art.494-1 & suiv",
    },
    {
      label: "Mandat de Protection Future Art 477 à 494",
      value: "Mandat de Protection Future Art 477 à 494",
    },
    {
      label: "Mandat à Effet Posthume Art. 812 & suiv.",
      value: "Mandat à Effet Posthume Art. 812 & suiv.",
    },
    {
      label: "Sauvegarde de justice Art. 433 & suiv.",
      value: "Sauvegarde de justice Art. 433 & suiv.",
    },
    { label: "Tutelle Art. 440", value: "Tutelle Art. 440" },
    {
      label: "Tutelle sous gestion privée Art 505",
      value: "Tutelle sous gestion privée Art 505",
    },
  ];

  return (
    <form
      onSubmit={form.handleSubmit((data) => {
        const formattedData = {
          ...data,
          pep: data.pep === "true",
          usPerson: data.usPerson === "true",
        };
        console.log(formattedData);
        mutation.mutate(formattedData);
      })}
    >
      <Accordion type="multiple" className="w-full" defaultValue={["item-1"]}>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.maritalStatus")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4 overflow-visible">
            <CustomInput
              type={"text"}
              key={"lastName"}
              {...form.register("lastName")}
              label={t(`forms.customers.details.general.lastName`)}
            />
            <CustomInput
              type={"text"}
              key={"birthName"}
              {...form.register("birthName")}
              label={t(`forms.customers.details.general.birthName`)}
            />
            <CustomInput
              type={"text"}
              key={"firstName"}
              {...form.register("firstName")}
              label={t(`forms.customers.details.general.firstName`)}
            />
            <CustomInput
              type={"date"}
              key={"birthDate"}
              {...form.register("birthDate")}
              label={t(`forms.customers.details.general.birthDate`)}
            />
            <CustomInput
              type={"text"}
              key={"birthCity"}
              {...form.register("birthCity")}
              label={t(`forms.customers.details.general.birthCity`)}
            />
            <CustomInput
              type={"text"}
              key={"birthCountry"}
              {...form.register("birthCountry")}
              label={t(`forms.customers.details.general.birthCountry`)}
            />
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.familySituation"
                name="familySituation"
              />
              <Controller
                key={"familySituation"}
                {...form.register("familySituation")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    name="familySituation"
                    value={maritalStatusOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t(
                      "forms.customers.details.general.familySituation"
                    )}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={maritalStatusOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                )}
              />
            </div>
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.nationality"
                name="nationality"
              />
              <Controller
                key={"nationality"}
                {...form.register("nationality")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t(
                      "forms.customers.details.general.nationality"
                    )}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={countriesOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger>
            {t(
              "forms.customers.details.general.accordion.professionalSituation"
            )}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4 overflow-visible">
            <CustomInput
              type={"text"}
              key={"studiesLevel"}
              {...form.register("studiesLevel")}
              label={t(`forms.customers.details.general.studiesLevel`)}
            />
            <CustomInput
              type={"text"}
              key={"occupation"}
              {...form.register("occupation")}
              label={t(`forms.customers.details.general.occupation`)}
            />
            <CustomInput
              type={"text"}
              key={"occupationWording"}
              {...form.register("occupationWording")}
              label={t(`forms.customers.details.general.occupationWording`)}
            />
            <CustomInput
              type={"number"}
              key={"retirementAge"}
              {...form.register("retirementAge")}
              label={t(`forms.customers.details.general.retirementAge`)}
            />
            <CustomInput
              type={"number"}
              key={"annualIncome"}
              {...form.register("annualIncome")}
              label={t(`forms.customers.details.general.annualIncome`)}
            />
            <CustomInput
              type={"number"}
              key={"globalHeritage"}
              {...form.register("globalHeritage")}
              label={t(`forms.customers.details.general.globalHeritage`)}
            />
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.heritageOrigin"
                name="heritageOrigin"
              />
              <Controller
                key={"heritageOrigin"}
                {...form.register("heritageOrigin")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t(
                      "forms.customers.details.general.heritageOrigin"
                    )}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={heritageOriginsOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.identity")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(identityFormData).map(([key, value]) =>
              key === "seniorAdvisor" ? (
                <div
                  key={key}
                  className="items-left flex flex-col justify-center pl-4"
                >
                  <p className="text-m font-bold tracking-tight text-primary">
                    {t(`forms.customers.details.general.seniorAdvisor`)}
                  </p>
                  <p className="text-sm text-primary-600">
                    {form.watch("seniorAdvisor")}
                  </p>
                </div>
              ) : (
                <CustomInput
                  type={value.type}
                  key={key}
                  {...form.register(key)}
                  label={t(`forms.customers.details.general.${key}`)}
                />
              )
            )}
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.others")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4 overflow-visible">
            <CustomInput
              type={"text"}
              key={"idMIF"}
              {...form.register("idMIF")}
              label={t(`forms.customers.details.general.idMIF`)}
            />
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.legalCapacity"
                name="legalCapacity"
              />
              <Controller
                key={"legalCapacity"}
                {...form.register("legalCapacity")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="legalCapacity"
                    value={legalCapacityOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t(
                      "forms.customers.details.general.legalCapacity"
                    )}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={legalCapacityOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.taxResidenceCountry"
                name="taxResidenceCountry"
              />
              <Controller
                key={"taxResidenceCountry"}
                {...form.register("taxResidenceCountry")}
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CreatableSelect
                    id={field.name}
                    {...field}
                    name="nationality"
                    value={countriesOptions?.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => field.onChange(option?.value)}
                    placeholder={t(
                      "forms.customers.details.general.taxResidenceCountry"
                    )}
                    formatCreateLabel={(inputValue: string) =>
                      t("formLabel.create_name", {
                        name: inputValue,
                      })
                    }
                    components={selectComponents}
                    options={countriesOptions}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        borderColor: "transparent",
                        backgroundColor: "white",
                        borderRadius: 12,
                        boxShadow: "0 !important",
                        "&:hover": {
                          border: "0 !important",
                        },
                        "*": {
                          boxShadow: "none !important",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
            <CustomInput
              type={"text"}
              key={"customerClassificationMIF"}
              {...form.register("customerClassificationMIF")}
              label={t(
                `forms.customers.details.general.customerClassificationMIF`
              )}
            />
            <CustomInput
              type={"date"}
              key={"classificationDate"}
              {...form.register("classificationDate")}
              label={t(`forms.customers.details.general.classificationDate`)}
            />

            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.pep"
                name="pep"
              />
              <Controller
                key={"pep"}
                control={form.control}
                name="pep"
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="flex flex-col space-y-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        {...field}
                        value="true"
                        checked={field?.value?.toString() === "true"}
                        onChange={() => field.onChange("true")}
                        className="mr-2"
                      />
                      {t(
                        "forms.customers.details.general.pepPossibleOptions.true"
                      )}
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        {...field}
                        value="false"
                        checked={field?.value?.toString() === "false"}
                        onChange={() => field.onChange("false")}
                        className="mr-2"
                      />
                      {t(
                        "forms.customers.details.general.pepPossibleOptions.false"
                      )}
                    </label>
                  </div>
                )}
              />
            </div>
            <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
              <FormLabel
                label="forms.customers.details.general.usPerson"
                name="usPerson"
              />
              <Controller
                key={"usPerson"}
                control={form.control}
                name="usPerson"
                rules={{ required: true }}
                render={({ field }) => (
                  <div className="flex flex-col space-y-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        {...field}
                        value="true"
                        checked={field?.value?.toString() === "true"}
                        onChange={() => field.onChange("true")}
                        className="mr-2"
                      />
                      {t(
                        "forms.customers.details.general.usPersonPossibleOptions.true"
                      )}
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        {...field}
                        value="false"
                        checked={field?.value?.toString() === "false"}
                        onChange={() => field.onChange("false")}
                        className="mr-2"
                      />
                      {t(
                        "forms.customers.details.general.usPersonPossibleOptions.false"
                      )}
                    </label>
                  </div>
                )}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Button
        label="actions.save"
        className="mt-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-400 px-4 py-2 font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        type="submit"
      />
    </form>
  );
};
