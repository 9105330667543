import { useTranslation } from "react-i18next";

import { AssetType, Page } from "@types";
import { Widget, Loading } from "components";
import { NormalizeTranslationKeys } from "i18n";
import { numberFormat, singleSCPIUrl } from "core";
import { DataType } from "components/charts/brush";
import { useGQLQuery } from "core/queries/useQuery";
import { EstateDetails, PeriodItem, SideDetails } from "../realEstate";
import { PerioActivityChart, PeriodActivityDataset } from "components/charts";

export const SCPI = ({ params }: Page) => {
  const id = params?.estate || "";
  const { t } = useTranslation();

  const { data, isLoading } = useGQLQuery(
    "singleSCPI" + id,
    singleSCPIUrl({ id })
  );

  if (isLoading) return <Loading />;

  const periodActivities: PeriodItem[] = [];
  const periodData = { periodActivities };

  for (const period of data?.privateCivilRealestate?.asset?.activities ?? []) {
    periodActivities.push({
      id: period.id,
      value: period.value,
      start: period.start,
      end: period.start,
    });
  }

  return (
    <div className="flex w-full flex-col justify-between lg:flex-row lg:gap-x-3">
      <div className="mb-3 flex w-full flex-col lg:mb-0 lg:w-[75%]">
        <div className="mb-5 flex flex-col items-stretch justify-between lg:flex-row lg:gap-x-3">
          <Widget
            className="mb-4 h-full w-full bg-white pb-6 lg:mb-0 lg:w-[49%]"
            label="title.informations"
            children={
              <EstateDetails
                details={[
                  {
                    label: "Type",
                    value: t(
                      `page.scpi.${data?.privateCivilRealestate?.type}` as NormalizeTranslationKeys
                    ),
                  },
                  {
                    label: t(`page.scpi.parts_quantity`),
                    value: data?.privateCivilRealestate?.quantity,
                  },
                  {
                    label: t(`formLabel.category`),
                    value: data?.privateCivilRealestate?.scpi?.type,
                  },
                ]}
              />
            }
          />
          <Widget
            className="mb-4 h-full w-full bg-white pb-6 lg:mb-0 lg:w-[49%]"
            label="widget.specifications"
            children={
              <EstateDetails
                details={[
                  {
                    label: t(`page.scpi.fees_manage`),
                    value: `${numberFormat(
                      data?.privateCivilRealestate?.scpi?.gestion_fees || 0,
                      {
                        showCurrency: false,
                      }
                    )} %`,
                  },
                  {
                    label: t(`page.scpi.fees_subscription`),
                    value: `${numberFormat(
                      data?.privateCivilRealestate?.scpi?.subscription_fees ||
                        0,
                      {
                        showCurrency: false,
                      }
                    )} %`,
                  },
                ]}
              />
            }
          />
        </div>
        <Widget
          className="w-full"
          label="widget.evolution"
          children={
            <PerioActivityChart
              dataset={
                periodData?.periodActivities?.map((e: DataType) => ({
                  date: e.start,
                  amount: {
                    value: e.value,
                  },
                })) as PeriodActivityDataset[]
              }
              hasDecimal={false}
              assetType={AssetType.privateCivilRealEstate}
            />
          }
        />
      </div>
      <SideDetails
        details={[
          {
            label: t(`page.scpi.unitPrice`),
            value: numberFormat(data?.privateCivilRealestate?.unitPrice),
          },
          {
            label: t(`page.scpi.dividend`),
            value: `${numberFormat(
              data?.privateCivilRealestate?.scpi?.dividend,
              {
                showCurrency: false,
              }
            )} %`,
          },
        ]}
      />
    </div>
  );
};
