import { useTranslation } from "react-i18next";
import { Tooltip } from "primereact/tooltip";
import { AssetType } from "@types";
import { AssetIcon } from "components/AssetIcon";
import EvolutionPercent from "components/EvolutionPercent";
import { clsx, getAssetTypeColor, numberFormat } from "core";
import {
  percentFormatter,
  evolutionAmountFormatterOptions,
} from "components/localization";

export type AssetRowType = {
  id: string;
  type: "evolution";
  assetType: AssetType;
  title?: string;
  instrument?: string;
  gain: number;
  value: number;
  evolution: number;
  icon?: string;
  Icon?: React.ReactNode;
  isConnected?: boolean;
  isUnderManagement?: boolean;
};

export type RepartitionRowType = {
  type: "repartition";
  title: string;
  value: number;
  assetType: AssetType;
  icon?: string;
  percent: number;
  isConnected?: boolean;
  instrument?: string;
};

export type ActionRowType = {
  type: "action";
  title: string;
  value: number;
  assetType: AssetType;
  icon?: string;
  Right: React.ReactNode;
  isConnected?: boolean;
  instrument?: string;
};

type RowProps = (AssetRowType | RepartitionRowType | ActionRowType) & {
  onClick?: () => void;
  className?: string;
  isUnderManagement?: boolean;
};

export function Row(props: RowProps) {
  const { assetType, type, className, isUnderManagement } = props;
  return (
    <div
      className={clsx("pl-2 pr-6", className)}
      onClick={
        !isUnderManagement || assetType === AssetType.lifeInsurance
          ? props.onClick
          : undefined
      }
    >
      <div
        className={`flex w-full ${
          !isUnderManagement || assetType === AssetType.lifeInsurance
            ? "cursor-pointer"
            : ""
        } items-stretch rounded-lg p-2 hover:bg-neutral-100`}
      >
        <div className="flex h-12 w-full items-center gap-2">
          {type === "evolution" && props.Icon ? (
            props.Icon
          ) : (
            <AssetIcon
              asset={assetType}
              size="medium"
              background="asset"
              icon={props.icon}
              isHome
              isConnected={props.isConnected}
            />
          )}

          <div className="relative h-full shrink grow truncate">
            {type === "repartition" && (
              <div
                className="absolute left-0 top-0 -z-10 h-full rounded-lg"
                style={{
                  width: `${Math.round(props.percent * 100)}%`,
                  backgroundColor: getAssetTypeColor(assetType).backgroundColor,
                }}
              />
            )}

            <div className="relative flex h-full w-full flex-col justify-around px-3">
              <Left {...props} />
            </div>
          </div>

          <div className="ml-auto flex h-full flex-col items-stretch justify-between">
            <Right {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Left({ title, ...props }: RowProps) {
  const { t } = useTranslation();
  const { assetType, instrument, isUnderManagement } = props;
  const gain = props.type === "evolution" ? props.gain : 0;

  const evolutionAmountFormatter = Intl.NumberFormat(undefined, {
    ...evolutionAmountFormatterOptions,
    signDisplay: "always",
    maximumFractionDigits: 2,
    currency: instrument || "EUR", // This should work now
  });
  switch (props.type) {
    case "evolution":
      return (
        <>
          <p
            className="flex flex-row truncate text-base font-bold leading-tight text-slate-900"
            title={title}
          >
            <span className="truncate">{title ?? t(`title.${assetType}`)}</span>

            {isUnderManagement && (
              <span className="">
                <Tooltip
                  target=".custom-target-icon"
                  className="text-sm [&>.p-tooltip-arrow]:!border-t-[#a6a6a6] [&>.p-tooltip-text]:border [&>.p-tooltip-text]:border-[#a6a6a6] [&>.p-tooltip-text]:!bg-white [&>.p-tooltip-text]:!p-0"
                />
                <i
                  className="pi pi-flag-fill custom-target-icon mx-2 cursor-pointer"
                  data-pr-tooltip={t("isUnderManagement")}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  aria-controls="management_menu"
                  style={{ color: "#4761C8" }}
                ></i>
              </span>
            )}
          </p>
          <p className="text-sm font-normal leading-none text-slate-600">
            {evolutionAmountFormatter.format(gain)}
          </p>
        </>
      );
    case "repartition":
    case "action":
      return (
        <div className="text-base font-bold leading-tight text-slate-900">
          <p
            className="truncate text-base font-bold leading-tight text-slate-900"
            title={title}
          >
            {title}
          </p>
          <p className="text-sm font-normal leading-none text-slate-600">
            {numberFormat(props.value)}
          </p>
        </div>
      );
    default:
      return null;
  }
}

function Right(props: RowProps) {
  switch (props.type) {
    case "evolution":
      return (
        <div className="flex h-full flex-col items-end justify-around">
          <span className="text-base font-bold leading-tight text-slate-600">
            {numberFormat(props.value, { maximumFractionDigits: 2 })}
          </span>
          <EvolutionPercent
            evolution={props.evolution}
            positive={props.gain - props.value > 0}
            size="large"
          />
        </div>
      );
    case "repartition":
      return (
        <div className="flex h-full items-center justify-around">
          <span
            className="rounded-lg px-1 text-xs font-bold text-white"
            style={{
              backgroundColor: getAssetTypeColor(props.assetType).color,
            }}
          >
            {percentFormatter.format(props.percent)}
          </span>
        </div>
      );
    case "action":
      return (
        <div className="flex h-full items-center justify-around">
          {props.Right}
        </div>
      );

    default:
      return null;
  }
}
