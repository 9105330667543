import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Tabs } from "components";
import { pick } from "core/helpers/object";
import { AuthService } from "core/services";
import { ManageCgp } from "./components/ManageCgp";
import { useUser } from "core/helpers/hooks/useUser";
import { getProfileTabsGroup } from "./utils/getTabsGroup";
import { AccountManagement } from "./components/AccountManagement";
import {
  getProCode,
  acceptProCode,
  deleteAcccountMutation,
  setUserSettingsSimulationMutation,
} from "core";

export const ProfileScreen = () => {
  // States & Variables
  const { data } = useQuery("code", getProCode);
  const userQuery = useUser();

  const code = data?.getProCode;
  const [enabled, setEnabled] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleAddHash = (hash: string) =>
    navigate(`${location.pathname}#${hash}`);

  // Events
  const handleAccountDeletion = async () => {
    try {
      if (!window.confirm(t("wordings.confirm_delete_account"))) return;
      await deleteAcccountMutation();
      AuthService.localLogout();
    } catch (error) {
      throw new Error(error?.toString() ?? "");
    }
  };

  const handleEnabled = (value: boolean) => {
    setEnabled(value);
    toast.success(
      t(value ? "notification.activated" : "notification.desactivated")
    );
  };

  useEffect(() => {
    setEnabled(!!userQuery.data?.enableCGPManagment);
  }, [userQuery.data]);

  if (!userQuery.data) return null;
  if (userQuery.isLoading) return null;

  const user = userQuery.data;

  const handleProCodeSubmition = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const code = (
      e.currentTarget.elements.namedItem("active_cgp") as HTMLInputElement
    ).value;
    try {
      await acceptProCode(code);

      await setUserSettingsSimulationMutation({
        settings: {
          ...pick(user, [
            "firstName",
            "lastName",
            "birthday",
            "location",
            "phoneNumber",
            "linkedin",
          ]),
          enableCGPManagment: enabled,
        },
      });
      userQuery.refetch();

      toast.success(t("notification.save"));
    } catch (error) {
      toast.error(t("notification.error.save"));
    }
  };

  const defaultValue = location.hash.substring(1);
  const tabsGroup = getProfileTabsGroup(
    { ...user, hasProCode: code ? true : false },
    handleAddHash
  );
  return (
    <div className="w-full pt-0">
      <Tabs
        defaultValue={defaultValue}
        tabsGroup={tabsGroup}
        user={user}
        bottomLeftContent={[
          <ManageCgp
            key={0}
            t={t}
            enabled={enabled}
            setEnabled={handleEnabled}
            onProCodeSubmit={handleProCodeSubmition}
            code={code}
          />,
          <AccountManagement
            key={1}
            t={t}
            status={!!user?.isPremium}
            handleDelete={handleAccountDeletion}
          />,
        ]}
      />
    </div>
  );
};
