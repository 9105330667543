/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Librairie : Hypo
 * Description : Répertoire de fonctions de mathématiques financières, spécialement orientée vers les emprunts et placements
 * Version : 1.0
 * Auteur : VinnyRoundfoot
 * GitHub : https://github.com/vinnyroundfoot/Hypo-js
 * Date : mars 2015
 *
 */

const Hypo = {
  VERSION: "1.0",
  AUTHOR: "VinnyRoundfoot",

  // Calcul de l'arrondi d'un nombre
  arrondi(m, dec) {
    let montantArrondi = m;
    if (typeof dec !== "undefined" && Number.isInteger(dec)) {
      const r = Math.pow(10, dec);
      montantArrondi = Math.round(m * r) / r;
    }
    return montantArrondi;
  },

  // Conversion d'une variable en nombre (si possible)
  convStrNum(p) {
    if (typeof p === "number") return p;
    if (typeof p === "string") {
      const c = p.replace(",", ".").replace(" ", "");
      if (!isNaN(c)) return parseFloat(c);
    }
    return NaN;
  },

  // Conversion d'un taux périodique vers une autre période
  convTx(taux, pOri, pDest, dec) {
    taux = this.convStrNum(taux);
    pOri = this.convStrNum(pOri);
    pDest = this.convStrNum(pDest);
    dec = this.convStrNum(dec);

    if (taux === 0) return 0;
    if (pOri === pDest) return taux;

    const tx = Math.pow(1 + taux, pOri / pDest) - 1;
    return this.arrondi(tx, dec);
  },

  // Calcul de la valeur acquise d'un capital K placé pendant n périodes à un taux t (intérêts composés)
  valeurAcquise(K0, n, t, dec) {
    if (t === 0 || n === 0) return K0;
    const K = K0 * Math.pow(1 + t, n);
    return this.arrondi(K, dec);
  },

  // Calcul de la valeur acquise d'une suite de placements pendant n périodes à un taux t (intérêts composés)
  VC(m, n, t, dec) {
    if (n === 0) return m;
    if (t === 0) return this.arrondi(m * n, dec);
    const Kn = (m * (Math.pow(1 + t, n) - 1)) / t;
    return this.arrondi(Kn, dec);
  },

  // Calcul de la valeur actuelle d'un capital Kn dont la valeur actuelle a été placée pendant n périodes à un taux t
  valeurActuelle(Kn, n, t, dec) {
    if (n === 0 || t === 0) return Kn;
    const Kzero = Kn / Math.pow(1 + t, n);
    return this.arrondi(Kzero, dec);
  },

  // Calcul de la valeur actuelle d'une suite de placements pendant n périodes à un taux t
  VA(m, n, t, dec) {
    m = this.convStrNum(m);
    n = this.convStrNum(n);
    t = this.convStrNum(t);
    dec = this.convStrNum(dec);

    if (t === 0) return this.arrondi(m * n, 2);
    const Kzero = (m * (1 - Math.pow(1 + t, -n))) / t;
    return this.arrondi(Kzero, dec);
  },

  // Calcul du taux en fonction du capital de départ (K0), du capital acquis (Kn) et du nombre de période (n)
  taux_Kn(K0, Kn, n, dec) {
    const r = Kn / K0;
    const tx = Math.pow(r, 1 / n) - 1;
    return this.arrondi(tx, dec);
  },

  // Approximation du taux en fonction du capital de départ K0, de la mensualité m et de la durée n
  taux(K0, n, m, f = 0) {
    m = this.convStrNum(m);
    n = this.convStrNum(n);
    K0 = this.convStrNum(K0);
    f = this.convStrNum(f);

    const M = (K0 - f) / m;
    let r0 = 0.01;
    let r1 = 0.01;
    let stopinc = 0;

    do {
      stopinc++;
      r0 = r1;
      const p = M * r0 * Math.pow(1 + r0, n) - Math.pow(1 + r0, n) + 1;
      const dp = M * Math.pow(1 + r0, n) + M * n * r0 * Math.pow(1 + r0, n - 1) - n * Math.pow(1 + r0, n - 1);
      r1 = this.arrondi(r0 - p / dp, 9);
    } while (r0 !== r1 && stopinc < 20);

    return stopinc === 20 ? -1 : r1;
  },

  // Calcul de la durée d'un placement en fonction du capital de départ, du capital acquis et du taux
  duree_Kn(K0, Kn, t) {
    K0 = this.convStrNum(K0);
    Kn = this.convStrNum(Kn);
    t = this.convStrNum(t);

    if (K0 === 0 || t === 0 || K0 === Kn) return 0;

    const r = Kn / K0;
    const duree = Math.log(r) / Math.log(1 + t);
    return duree;
  },

  // Calcul de la durée d'un placement en fonction du capital à obtenir, de la mensualité et du taux
  duree(K0, m, t) {
    K0 = this.convStrNum(K0);
    m = this.convStrNum(m);
    t = this.convStrNum(t);

    if (t === 0) return this.arrondi(K0 / m, 2);

    return (Math.log(m) - Math.log(m - K0 * t)) / Math.log(1 + t);
  },

  // Calcul de la mensualité d'un emprunt K0 souscrit pour n périodes à un taux t
  VPM(K0, n, t, dec) {
    K0 = this.convStrNum(K0);
    n = this.convStrNum(n);
    t = this.convStrNum(t);
    dec = this.convStrNum(dec);

    if (n === 0) return 0;
    if (t === 0) return this.arrondi(K0 / n, dec);

    return this.arrondi((K0 * t) / (1 - Math.pow(1 + t, -n)), dec);
  },

  // Calcul de la mensualité d'un placement pendant n périodes à un taux t en fonction de la valeur acquise souhaitée
  VPM_Kn(Kn, n, t, dec) {
    if (t === 0) return this.arrondi(Kn / n, 2);
    return this.arrondi((Kn * t) / (Math.pow(1 + t, n) - 1), dec);
  },

  // Pour un emprunt remboursé en n périodes à un taux t et présentant n_amd périodes d'amortissement différé
  VPM_amd(K0, n, t, n_amd, dec) {
    const m_amd = this.arrondi(K0 * t, 2);
    const intp = m_amd * n_amd;
    const m = this.VPM(K0, n - n_amd, t, dec);
    return { m_amd, m, cumulInt: intp };
  },

  // Calcul de l'amortissement (A) de 1ere période d'un emprunt K0 souscrit pour n périodes à un taux t
  amortissementP1(K0, n, t, dec) {
    if (n === 0) return K0;
    if (t === 0) return this.VPM(K0, n, t, dec);

    const A = (K0 * t) / (Math.pow(1 + t, n) - 1);
    return this.arrondi(A, dec);
  },

  // Calcul de l'amortissement (Apn) de la période p d'un emprunt K0 souscrit pour n périodes à un taux t
  amortissementPn(K0, n, t, p, dec) {
    K0 = this.convStrNum(K0);
    n = this.convStrNum(n);
    t = this.convStrNum(t);
    p = this.convStrNum(p);
    dec = this.convStrNum(dec);

    const A = this.amortissementP1(K0, n, t, dec);
    if (p === 1 || t === 0) return this.arrondi(A, dec);

    const Apn = Math.pow(1 + t, p - 1) * A;
    return this.arrondi(Apn, dec);
  },

  // Calcul de l'amortissement (Apn2) de la période p d'un emprunt souscrit pour n périodes à un taux t en fonction de l'amortissement d'un période donné (apn1)
  amortissementPnp(n, t, apn1, p1, p2, dec) {
    if (t === 0) return apn1;

    const apn2 = apn1 * Math.pow(1 + t, p2 - p1);
    return this.arrondi(apn2, dec);
  },

  // Calcul des amortissements cumulés de la période p1 à la période p2 d'un emprunt souscrit pour n périodes à un taux t
  cumulPrinc(K0, n, t, p1, p2, dec) {
    K0 = this.convStrNum(K0);
    n = this.convStrNum(n);
    t = this.convStrNum(t);
    p1 = this.convStrNum(p1);
    p2 = this.convStrNum(p2);
    dec = this.convStrNum(dec);

    let pp1 = p1;
    let pp2 = p2;
    let sump = 0;
    const m = this.VPM(K0, n, t, dec);

    while (pp1 <= pp2) {
      sump += this.amortissementPn(K0, n, t, pp1);
      pp1++;
    }
    return this.arrondi(sump, dec);
  },

  // Calcul de l'intérêt (ipn) de la période p d'un emprunt K0 souscrit pour n périodes à un taux t
  interetsPn(K0, n, t, p, dec) {
    K0 = this.convStrNum(K0);
    n = this.convStrNum(n);
    t = this.convStrNum(t);
    p = this.convStrNum(p);
    dec = this.convStrNum(dec);

    const m = this.VPM(K0, n, t, dec);
    const apn = this.amortissementPn(K0, n, t, p, dec);
    return this.arrondi(m - apn, dec);
  },

  cumulInt(K0, n, t, p1, p2, dec) {
    if (t === 0) return 0;

    let ip1 = p1;
    let ip2 = p2;
    let sumi = 0;
    const m = this.VPM(K0, n, t, dec);

    while (ip1 <= ip2) {
      sumi += m - this.amortissementPn(K0, n, t, ip1);
      ip1++;
    }
    return this.arrondi(sumi, dec);
  },

  intTotaux(K0, m, n, dec) {
    if (K0 === 0 || m === 0 || n === 0) return 0;
    return this.arrondi(m * n - K0, dec);
  },

  // Calcul du solde restant dû à la période p d'un emprunt K0 souscrit pour n périodes à un taux t
  SRDPn_K(K0, n, t, p, dec) {
    const m = this.VPM(K0, n, t);
    const kpn = (m * (1 - Math.pow(1 + t, -(n - p)))) / t;
    return this.arrondi(kpn, dec);
  },

  // Calcul du solde restant dû à la période p d'un emprunt remboursé en n période d'un montant m à un taux t
  SRDPn(m, n, t, p, dec) {
    const kpn = (m * (1 - Math.pow(1 + t, -(n - p)))) / t;
    return this.arrondi(kpn, dec);
  },

  // Calcul du capital remboursé à la période p d'un emprunt K0 souscrit pour n périodes à un taux t
  capRmb_K(K0, n, t, p, dec) {
    const A = this.amortissementP1(K0, n, t);
    const Kr = A * ((Math.pow(1 + t, p) - 1) / t);
    return this.arrondi(Kr, dec);
  },

  // Détermination du tableau d'amortissement pour un emprunt de K0 pour une durée n à un taux t
  tableauAmort(K0, n, t, p1 = 1, p2 = n) {
    let i = p1 - 1;
    const mens = this.VPM(K0, n, t, 2);
    let srd = i === 0 ? K0 : this.SRDPn(mens, n, t, i);
    const tableau = [{ K0, n, t, m: mens, p1, p2 }];

    while (srd > 0 && i < p2) {
      i++;
      const ligne = {
        periode: i,
        solde_dep: srd,
        interet: srd * t,
        amort: this.arrondi(mens - srd * t, 2),
        srd: this.arrondi(srd - this.arrondi(mens - srd * t, 2), 2),
      };
      srd = ligne.srd;
      tableau.push(ligne);
    }

    return tableau;
  },

  // Détermination du tableau d'amortissement pour un emprunt de K0 pour une durée n à un taux t avec un amortissement différé de n_amd périodes
  tableauAmort_amd(K0, n, t, n_amd = 0, p1 = 1, p2 = n) {
    if (n_amd === 0) return this.tableauAmort(K0, n, t, p1, p2);

    const amd = this.VPM_amd(K0, n, t, n_amd, 2);
    let i = 0;
    let mens = amd.m_amd;
    let srd = i === 0 ? K0 : this.SRDPn(mens, n, t, i);
    const tableau = [{ K0, n, t, m_amd: mens, p1, p2 }];

    while (i < n_amd) {
      i++;
      const ligne = {
        periode: i,
        solde_dep: K0,
        interet: this.arrondi(K0 * t, 2),
        amort: 0,
        srd: K0,
      };
      tableau.push(ligne);
    }

    srd = K0;
    mens = this.VPM(srd, n - n_amd, t, 2);
    tableau[0].m = mens;

    while (srd > 0 && i < n) {
      i++;
      const ligne = {
        periode: i,
        solde_dep: srd,
        interet: this.arrondi(srd * t, 2),
        amort: this.arrondi(mens - this.arrondi(srd * t, 2), 2),
        srd: this.arrondi(srd - this.arrondi(mens - this.arrondi(srd * t, 2), 2), 2),
      };
      srd = ligne.srd;
      tableau.push(ligne);
    }

    return [tableau[0]].concat(tableau.slice(p1, p2 + 1));
  },

  // Calcul du TAEG
  calcTAEGMens(K0, n, m, f) {
    const mens = m;
    let min = 0.000001;
    let max = 1;
    let tst = (min + max) / 2;

    const val_polyn = (p_test) => (mens * (1 - (1 + p_test) ** -n)) / p_test - K0 + f;

    let f_min = val_polyn(min);
    if (f_min * val_polyn(max) > 0) return -1;

    while (max - min > 1 / 10000000) {
      const f_tst = val_polyn(tst);
      if (f_min * f_tst < 0) {
        max = tst;
      } else {
        min = tst;
        f_min = f_tst;
      }
      tst = (min + max) / 2;
    }
    return this.convTx(tst, 12, 1, 4);
  },
};

const periodes = {
  Ann: 1,
  Sem: 2,
  Trim: 4,
  Mens: 12,
};

Object.keys(periodes).forEach((j) => {
  Object.keys(periodes).forEach((i) => {
    if (i !== j) {
      Hypo[`tx${j}${i}`] = (taux, dec) => Hypo.convTx(taux, periodes[j], periodes[i], dec);
    }
  });
});

export default Hypo;