import { VFC } from "react";
import { useQueryClient } from "react-query";

import { Modal, SorTable, Typo } from "components";
import { addActivityMutation } from "core/queries/mutation";
import { AssetStatus, AssetType, ManualUpdateModalProps } from "@types";
import {
  activityForm,
  updateAction,
  activityNameForm,
  updateAssetMutation,
} from "core";
import { FormBuilder } from "components/formBuilder";
import { useAppSelector } from "_redux";

const useManualAddModal = ({
  selected,
  queryType,
  onClose,
  handleDelete,
}: ManualUpdateModalProps) => {
  const queryClient = useQueryClient();
  const period = useAppSelector((state) => state.period.value);

  const updateName = async (params: any) => {
    try {
      if (!selected?.asset.id) return;
      const res = await updateAssetMutation({
        params: {
          assetId: params.id,
          name: params.name,
        },
      });

      if (!res) return;
      queryClient.invalidateQueries(["assets-" + queryType]);

      onClose();
    } catch (error) {
      throw new Error(error?.toString() ?? '');
    }
  };
  const onSubmit = async (input: any) => {
    try {
      if (!selected?.asset.id) return;

      const res = await addActivityMutation({
        input,
        assetId: selected.asset.id,
      });

      if (!res) return;
      queryClient.invalidateQueries(["totalWealth", queryType, period]);

      onClose();
    } catch (error) {
      throw new Error(error?.toString() ?? '');
    }
  };
  const onDelete = (id: string) => {
    handleDelete(id);
    onClose();
  };
  const activities = selected?.asset?.activities || [];
  const transactions =
    (queryType === AssetType.bankAccount && selected?.asset?.transactions) ||
    [];
  const isManual = selected?.asset?.status === AssetStatus.manual;
  const showTransactions = ![AssetType.retirements].includes(
    queryType as AssetType
  );

  return {
    period,
    selected,
    queryType,
    activities,
    isManual,
    queryClient,
    transactions,
    showTransactions,
    onClose,
    onDelete,
    onSubmit,
    updateName,
    handleDelete,
  };
};

export const ManualAddModalWithActivity: VFC<ManualUpdateModalProps> = (
  props
) => {
  const {
    activities,
    transactions,
    isManual,
    showTransactions,
    updateName,
    onSubmit,
    onDelete,
  } = useManualAddModal(props);

  const { selected, onClose } = props;

  if (!selected) return <>No selection</>;

  return (
    <Modal
      open={true}
      setOpen={onClose}
      className="my-modal w-11/12 bg-gray-1200 p-5 lg:max-w-[1300px]"
    >
      <FormBuilder
        {...activityNameForm}
        onSubmit={updateName}
        clasName="w-full justify-center"
        defaultValues={{
          id: selected?.asset?.id,
          name: selected.title,
        }}
        action={({ submit }) => (
          <div className="nom-de-compte">
            <div className="submit-nom-compte">
              <button
                type="submit"
                onClick={submit}
                className="text-secondary button-modal mx-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-100 px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <img src="./verifier.png" alt="Valider nouveau nom de compte" />
              </button>
            </div>
          </div>
        )}
      />
      {isManual && (
        <FormBuilder
          clasName="mt-8"
          {...activityForm}
          action={updateAction}
          onSubmit={onSubmit}
        />
      )}
      {showTransactions && !isManual && (
        <div className="m-auto mb-7 w-5/6 overflow-hidden rounded-lg border border-gray-200 bg-gray-1400 p-0">
          <div className="flex items-center rounded-t-lg bg-gray-1400 py-3">
            <div className="right-0 mr-3 h-9 w-[6px] rounded-r-lg bg-secondary-500" />
            <Typo type="wlc_inter_l" className="mb-0 text-center">
              Vos transactions
            </Typo>
          </div>
          <SorTable
            type="transactions"
            datas={transactions}
            showLabel
            handleDelete={isManual ? onDelete : undefined}
          />
        </div>
      )}
      <div className="m-auto mb-7 w-5/6 overflow-hidden rounded-lg border border-gray-200 bg-gray-1400 p-0">
        <div className="flex items-center rounded-t-lg bg-gray-1400 py-3">
          <div className="right-0 mr-3 h-9 w-[6px] rounded-r-lg bg-secondary-500" />
          <Typo type="wlc_inter_l" className="mb-0 text-center">
            Votre solde
          </Typo>
        </div>

        <SorTable
          type="amount"
          datas={activities.map((e: any) => ({
            id: e.id,
            amount: e.value,
            date: e.start,
          }))}
          handleDelete={isManual ? onDelete : undefined}
        />
      </div>
    </Modal>
  );
};
