import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "core/helpers/check";

export const notificationReducer = createSlice({
  name: "notification",
  initialState: {
    value: [],
    hasUnread: false,
  },
  reducers: {
    updateNotification: (state, action) => {
      if (!state.value?.length) state.value = [...(action.payload?.results ?? [])];
      else if (!isEmpty(action.payload) && !action.payload?.results)
        state.value = [action.payload, ...(state.value ? state.value.slice(0, -1) : [])];
    },
    updateUnreadNotification: (state, action) => {
      state.hasUnread = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateNotification, updateUnreadNotification } =
  notificationReducer.actions;

export default notificationReducer.reducer;
