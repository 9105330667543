import { VFC } from "react";

import { RadioTypes } from "@types";
import { CheckBoxRadioListWrapper } from "./FormLabel";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

export const RadioRender = ({
  id,
  name,
  value,
  label,
  subLine,
  required,
  register,
  onChange,
}: RadioTypes) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={id || value}
          type="radio"
          value={value}
          className="h-4 w-4 border-primary-200 text-primary focus:ring-primary"
          {...(register
            ? register(name, { required })
            : { name, required: !!required, onChange })}
        />
      </div>
      <div className="ml-1 flex flex-col text-sm">
        <label
          htmlFor={id || value}
          className="labelRadio ml-3 block cursor-pointer text-sm font-medium text-gray-800"
        >
          {t(label as NormalizeTranslationKeys) as string}
        </label>
        {subLine && (
          <label
            htmlFor={id || value}
            className="ml-3 block cursor-pointer text-sm font-medium text-gray-700"
          >
            {subLine}
          </label>
        )}
      </div>
    </div>
  );
};

export const Radio: VFC<RadioTypes> = (props) => {
  if (props.options)
    return (
      <CheckBoxRadioListWrapper {...props}>
        {props.options.map((option, index) => (
          <RadioRender
            key={index}
            {...props}
            {...option}
            name={props.name}
            id={`${props.name}-${option.label}`}
          />
        ))}
      </CheckBoxRadioListWrapper>
    );

  return <RadioRender {...props} />;
};
