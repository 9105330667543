import { FC } from "react";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { isStr } from "core/helpers/check";
import { NormalizeTranslationKeys } from "i18n";
import { CheckboxTypes, RadioTypes, reactString } from "@types";

interface FormLabelProps {
  name: string;
  label: reactString;
  className?: string;
}

const defaultLabelStyle = "block mb-1 text-sm font-medium text-gray-700";
export const FormLabel = ({ name, label, className }: FormLabelProps) => {
  const { t } = useTranslation();

  return isStr(label) ? (
    <label
      htmlFor={name}
      className={clsx(
        "ml-3 w-1/2 text-xs font-bold tracking-tight text-primary",
        className
      )}
    >
      <>{t(label as NormalizeTranslationKeys)}</>
    </label>
  ) : (
    <div className={defaultLabelStyle}>{label}</div>
  );
};
// Checkbox / Radio
const CheckBoxRadioLabel = ({
  className = "text-lg font-bold text-primary",
  ...props
}: FormLabelProps) => <FormLabel {...props} className={className} />;

type CheckBoxRadioListWrapperProps = RadioTypes | CheckboxTypes;

export const CheckBoxRadioListWrapper: FC<CheckBoxRadioListWrapperProps> = ({
  inline = false,
  children,
  ...props
}) => (
  <>
    {!!props.label && (
      <CheckBoxRadioLabel
        name={props.name}
        label={props.label}
        className="checkbox mb-1 ml-0 w-full text-base"
      />
    )}
    <div
      className={clsx(
        "flex gap-4",
        !inline && "flex-col gap-y-2",
        props.className
      )}
    >
      {children}
    </div>
  </>
);
