import { NormalizeTranslationKeys } from "i18n";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { Typo, ContainerProps } from "components";

interface LabelProps {
  label?: NormalizeTranslationKeys;
  Right?: React.ReactNode;
}
interface WidgetProps extends ContainerProps {
  label?: LabelProps["label"];
  Right?: React.ReactNode;
  containerClass?: string;
}

export const Label = ({ label, Right }: LabelProps) => {
  const { t } = useTranslation();

  return (
    <div className="mb-6 flex h-10 items-center justify-between">
      <div className="flex items-center">
        <Typo
          type="wlc_inter_xl"
          className="text-base tracking-tighter text-primary"
        >
          <>{t(label as NormalizeTranslationKeys)}</>
        </Typo>
      </div>
      {Right}
    </div>
  );
};

export const Widget = ({
  label,
  children,
  Right,
  className,
  containerClass,
}: WidgetProps) => (
  <div
    className={clsx(
      "relative h-full flex-1 flex-col overflow-hidden rounded-xl bg-white p-4 drop-shadow-md",
      className
    )}
  >
    {!!label && <Label label={label} Right={Right} />}
    <div
      className={clsx("h-full w-full flex-1 rounded-none p-0", containerClass)}
    >
      {children}
    </div>
  </div>
);
