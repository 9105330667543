import { TFunction } from "i18next";
import { Link, NavLink } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactElement, useEffect } from "react";

import { clsx } from "core";
import { NavMenu } from "./NavMenu";
import { AuthService } from "core/services";
import { Page, UserInterface } from "@types";
import { Icon, Period, Typo } from "components";
import { routes, RoutesType, RouteListType } from "routing";
import { RadioSelect } from "components/RadioSelect";
import { useTranslation } from "react-i18next";

export interface SidebarProps extends Page {
  data: any;
  t: TFunction;
  name?: string;
  actif?: RoutesType;
  isHome: boolean;
  className?: string;
  isLoading: boolean;
  route: RouteListType;
  sidebarOpen: boolean;
  isWalletAddress: boolean;
  user: UserInterface;
  children: ReactElement | ReactElement[];
  setSidebarOpen: (open: boolean) => void;
}
export interface HeaderTotalWealthsProps {
  data: any;
  period: Period;
  showBrut: boolean;
  route: RouteListType;
}

export function Sidebar(props: SidebarProps) {
  return (
    <>
      <MobileSideBar {...props} />
      <DesktopSidebar {...props} />
    </>
  );
}

function MobileSideBar({
  t,
  user,
  sidebarOpen,
  setSidebarOpen,
  route,
}: SidebarProps) {
  const initials = user.name.toUpperCase();

  useEffect(() => {
    document.body.style.overflowY = sidebarOpen ? "hidden" : "auto";
  }, [sidebarOpen]);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex max-h-screen md_lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-primary pb-4 pt-5">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon
                    className="h-6 w-6 text-primary-500"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <HeaderComponent name={user.name} />
            <div className="my-nav-menu mt-5 h-0 flex-1 overflow-y-auto">
              <NavMenu t={t} route={route} />
            </div>
            <BottomComponent
              route={route}
              user={user}
              initials={initials}
              t={t}
            />
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function DesktopSidebar({ t, user, route }: SidebarProps) {
  const initials = (
    user?.firstName && user?.lastName
      ? user?.firstName[0]?.[0] + user?.lastName[0]?.[0]
      : ""
  ).toUpperCase();
  const name =
    user?.firstName && user?.lastName
      ? user?.firstName + " " + user?.lastName
      : user?.email.split("@")[0];

  return (
    <div className="my-sidebar hidden bg-primary-1100 md_lg:fixed md_lg:inset-y-0 md_lg:flex md_lg:w-[250px] md_lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-grow flex-col overflow-y-auto">
        <HeaderComponent name={name} />
        <div className="my-nav-menu flex flex-grow flex-col">
          <NavMenu t={t} route={route} />
        </div>
      </div>
      <BottomComponent route={route} user={user} initials={initials} t={t} />
    </div>
  );
}

function HeaderComponent({ name }: { name: string | undefined }) {
  return (
    <>
      <div className="py-8 text-center">
        <NavLink to="/" className="m-auto block">
          <Icon type="wc_logo" className="w-24" width={128} height={128} />
        </NavLink>
      </div>
      <div className="mb-8 flex items-center justify-center gap-2 break-all text-primary-500">
        <NavLink to={routes.profile.path} className="m-auto block">
          <Typo
            type="wlc_inter_xl"
            children={name}
            className="username mb-0 font-['Inter'] font-bold  text-white _700:text-[17px]"
          />
        </NavLink>
      </div>
    </>
  );
}

function BottomComponent({
  t,
  route,
  user,
  initials,
}: Pick<SidebarProps, "user" | "route" | "t"> & {
  initials: string;
}) {
  const { i18n } = useTranslation();
  let component: JSX.Element | null = null;
  const isPremiumRoute = route.key === "premium";

  switch (user?.plan) {
    case "free":
      component = (
        <Link
          to={routes.profile.path + "#abonner-premium"}
          className="w-full px-4 py-8"
        >
          <img src="/starter.svg" alt="Starter" className="m-auto" />
        </Link>
      );
      break;
    case "premium":
      component = (
        <NavLink
          to={routes.profile.path + "#abonner-premium"}
          className="group relative mb-6 block h-12 w-full items-center py-[10px] font-semibold"
        >
          <div
            className={clsx(
              "flex items-center justify-center gap-2 text-center text-2xl text-primary-400 group-hover:text-white",
              isPremiumRoute && "premium-menu-active text-white"
            )}
          >
            <p className="premium-btn">{t("sidebar.premium.title")}</p>
            <Icon type="wc_gold_badge" />
          </div>
        </NavLink>
      );
      break;
    case "pro_simple":
      component = (
        <div className="w-full px-4 py-6">
          <img src="/starter.svg" alt="Starter" className="m-auto" />
        </div>
      );
      break;
    case "pro_premium":
      component = (
        <div className="group relative mb-6 block h-12 w-full items-center py-[10px] font-semibold">
          <div
            className={clsx(
              "flex items-center justify-center gap-2 text-center text-2xl text-primary-400 group-hover:text-white",
              isPremiumRoute && "premium-menu-active text-white"
            )}
          >
            <p className="premium-btn">{t("sidebar.premium.title")}</p>
            <Icon type="wc_gold_badge" />
          </div>
        </div>
      );
      break;
  }

  const changeLang = async (lng: string) => {
    await i18n.changeLanguage(lng); // Change the application language
    localStorage.setItem("i18nextLng", lng); // Save the user's choice in localStorage
  };

  const lang = i18n.language;

  return (
    <>
      {component}
      <div className="mb-6 flex w-full items-center justify-center">
        <div className="group relative">
          <div className="flex items-center gap-1">
            <Icon type={`${lang}-flag`} className="h-5" />
            <Icon type="wc_arrowUp" className="fill-arrow-menu " rotate={90} />
          </div>
          <RadioSelect
            position="top"
            defaultValue={lang}
            list={[
              {
                element: <Icon type="fr-flag" className="h-5" />,
                id: "fr",
              },
              {
                element: <Icon type="en-flag" className="h-5" />,
                id: "en",
              },
              {
                element: <Icon type="es-flag" className="h-5" />,
                id: "es",
              },
              {
                element: <Icon type="de-flag" className="h-5" />,
                id: "de",
              },
            ]}
            onChange={changeLang}
            className="w-28"
          />
        </div>
        <Link
          to={routes.profile.path}
          className="mr-4 h-12 w-12 cursor-pointer items-center justify-center pt-[11px] text-center"
        >
          <Typo type="wlc_inter_xl" className="text-white">
            {user?.firstName && user?.lastName ? initials : user?.email[0]}
          </Typo>
        </Link>
        <Link
          className="group relative h-12 w-12 cursor-pointer items-center rounded-full"
          to="/login"
          onClick={AuthService.localLogout}
        >
          <Icon type="wc_logout" />
          <Typo
            type="wlc_inter_s"
            className="absolute -left-6 -top-16 hidden w-fit items-center rounded-lg border border-gray-1500 bg-white px-3 py-3 shadow-lg group-hover:flex"
          >
            {t("sidebar.logout")}
          </Typo>
        </Link>
      </div>
    </>
  );
}
