import { useEffect, useState, VFC } from "react";
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { SupportIcon, CreditCardIcon } from "@heroicons/react/outline";

import { routes, RoutesType } from "routing";
import { NormalizeTranslationKeys } from "i18n";
import { selectComponents } from "core/helpers/form";
import { FormBuilder } from "components/formBuilder";
import { createPlatform } from "core/queries/mutation";
import { AssetType, FormBuilderProps, Option } from "@types";
import { action, getAssetTypeColor, manualPlatformAccountsUrl } from "core";
import { privateCivilRealEstateCreationForm } from "core/constants/form/manual/others";
import {
  Icon,
  Input,
  FormLabel,
  VerticalTabs,
  BudgetInsight,
  CustomInstrumentSelect,
} from "components";

type formKeys = "quantity" | "code" | "name" | "platformId";
const StockForm = ({
  onSubmit,
  type,
}: {
  onSubmit: SubmitHandler<FieldValues>;
  type: RoutesType;
}) => {
  const [platformOptions, setPlatformOptions] = useState<Option[]>([]);
  const { t } = useTranslation();
  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      quantity: 1,
      code: undefined,
      name: undefined,
      type: undefined,
      platformId: undefined,
      unitPrice: undefined,
      creation: undefined,
    },
  });
  const [values, setReactSelectValue] = useState<any>({});
  const isNotCrypto = type !== "crypto";

  const urlParams = new URLSearchParams(window.location.search);
  const actifValue = urlParams.get("actif");

  const onChange = (name: formKeys) => async (props: any) => {
    const value = props.value;

    if (props.__isNew__) {
      const { createPlaform } = await createPlatform({
        input: {
          name: value,
          exchange: value,
          type: isNotCrypto ? AssetType.stocks : type,
        },
      });
      setValue(name, createPlaform.id as any);
      setReactSelectValue({
        label: createPlaform.name,
        value: createPlaform.id,
      });
    } else {
      setValue(name, value);
      setReactSelectValue(props);
      if (props.code) setValue("code", props.code);
    }
  };

  useEffect(() => {
    if (isNotCrypto) {
      setValue("type", type as any);
    }
    const effect = async () => {
      const { manualPlatformAccounts } = await manualPlatformAccountsUrl({
        type: (isNotCrypto ? AssetType.stocks : type) as AssetType,
      })();

      setPlatformOptions(
        manualPlatformAccounts?.map((e) => ({
          label: e.name,
          value: e.id,
        })) || []
      );
    };
    effect();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-col items-center"
    >
      <div className="mb-12 grid w-full grid-cols-1 gap-4 md:grid-cols-3">
        <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
          <FormLabel label="formLabel.name" name="name" />
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomInstrumentSelect
                {...field}
                value={values.name}
                category={type}
                placeholder={
                  isNotCrypto
                    ? t("forms.stockOrCrypto.name.placeholder.stock")
                    : t("forms.stockOrCrypto.name.placeholder.crypto")
                }
                onChange={onChange("name")}
              />
            )}
          />
        </div>
        <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
          <FormLabel
            label="forms.stockOrCrypto.platformId.label"
            name="platformId"
          />
          <Controller
            name="platformId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                name="platformId"
                placeholder={t("forms.stockOrCrypto.platformId.placeholder")}
                formatCreateLabel={(inputValue: string) =>
                  t("formLabel.create_name", {
                    name: inputValue,
                  })
                }
                value={values.platformId}
                components={selectComponents}
                options={platformOptions}
                onChange={onChange("platformId")}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: "none",
                    borderColor: "transparent",
                    backgroundColor: "white",
                    borderRadius: 12,
                    boxShadow: "0 !important",
                    "&:hover": {
                      border: "0 !important",
                    },
                    "*": {
                      boxShadow: "none !important",
                    },
                  }),
                }}
              />
            )}
          />
        </div>
        <Input
          name="quantity"
          label="forms.stockOrCrypto.quantity.label"
          step="any"
          placeholder="forms.stockOrCrypto.quantity.placeholder"
          min={0.0001}
          type="number"
          required
          register={register as any}
        />

        <Input
          type="number"
          name="unitPrice"
          placeholder="forms.stockOrCrypto.unitPrice.placeholder"
          step="any"
          min={0.0001}
          label="forms.stockOrCrypto.unitPrice.label"
          required
          register={register as any}
        />

        <Input
          type="date"
          name="creation"
          label="forms.stockOrCrypto.creation.label"
          required
          register={register as any}
        />
      </div>
      <div className="section-action-button">
        <button
          type="submit"
          className="w-[32%] rounded-xl py-3 font-bold text-white"
          style={{
            backgroundColor: getAssetTypeColor(actifValue as AssetType).color,
          }}
        >
          {t("actions.add")}
        </button>
      </div>
    </form>
  );
};
interface ContentProps {
  icon?: string;
  biUrl: string;
  type: RoutesType;
  isStocksOrCrypto: boolean;
  formParams: FormBuilderProps;
  redirectTo: (type: RoutesType) => () => void;
  onSubmit: FormBuilderProps["onSubmit"];
}
export const Content: VFC<ContentProps> = ({
  type,
  biUrl,
  formParams,
  isStocksOrCrypto,
  onSubmit,
  redirectTo,
}) => {
  const { t } = useTranslation();
  const isRealEstateOrScpi =
    type === AssetType.realEstate || type === AssetType.privateCivilRealEstate;
  const isScpi = type === AssetType.privateCivilRealEstate;
  const tabs = [
    {
      title: (isRealEstateOrScpi
        ? "sidebar.realEstate.title"
        : `${t("actions.add")} ${t(
            routes[type].label
          )}`) as NormalizeTranslationKeys,
      component: (
        <>
          {isStocksOrCrypto ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <StockForm
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onSubmit={onSubmit!}
              type={(type === AssetType.stocks ? "stock" : type) as RoutesType}
            />
          ) : (
            <FormBuilder {...formParams} onSubmit={onSubmit} />
          )}
        </>
      ),
      icon:
        AssetType.realEstate === type ? (
          <Icon
            type="real-estate-house"
            className="mr-4 h-6 w-6 flex-shrink-0"
          />
        ) : (
          <CreditCardIcon className="mr-4 h-6 w-6 flex-shrink-0" />
        ),
      onClick: isRealEstateOrScpi
        ? redirectTo(routes.realEstate.key)
        : undefined,
    },
    ...([AssetType.exotic].includes(type as AssetType)
      ? []
      : [AssetType.privateCivilRealEstate, AssetType.realEstate].includes(
          type as AssetType
        )
      ? [
          {
            title: "widget.scpi" as NormalizeTranslationKeys,
            component: (
              <FormBuilder
                action={action}
                items={privateCivilRealEstateCreationForm}
                onSubmit={onSubmit}
              />
            ),
            icon: <Icon type="real-estate-percent" className="mr-4 h-6 w-6" />,
            onClick: redirectTo(routes.privateCivilRealEstate.key),
          },
        ]
      : [
          {
            title: "widget.automatic" as NormalizeTranslationKeys,
            component: <BudgetInsight />,
            icon: <SupportIcon className="mr-4 h-6 w-6 flex-shrink-0" />,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              window.open(biUrl, "_self");
            },
          },
        ]),
  ];

  return (
    <>
      <VerticalTabs tabs={tabs} defaultTab={isScpi ? 1 : 0} />
      <div className="flex w-full items-center justify-between">
        <button
          type="button"
          className="back-button"
          onClick={redirectTo(routes.home.key)}
        >
          <ArrowLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          {t("actions.back")}
        </button>
      </div>
    </>
  );
};
